import styled from '@emotion/styled';
import { TableName } from './table-name';
import { BalanceBetButtons } from './balance-bet-buttons';
import { Chat } from './chat';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2.4rem;

  .chat-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    margin-top: 0.7rem;
  }

  .bottom-button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
  }
`;

interface Props {
  betAmount: number;
  credits: number;
  previousWin: number;
}

const LeftColumn = (props: Props) => {
  const { betAmount, credits, previousWin } = props;

  const hasPreviousWin = previousWin > 0;

  return (
    <Container>
      <TableName />
      <div className="chat-wrapper">
        <Chat />
      </div>
      <div className="bottom-button-row">
        <BalanceBetButtons title="Balance" leftSide={true}>
          ${credits}
        </BalanceBetButtons>

        <BalanceBetButtons title={hasPreviousWin ? 'Last Win' : 'Total Bet'}>
          ${hasPreviousWin ? previousWin : betAmount}
        </BalanceBetButtons>
      </div>
    </Container>
  );
};

export { LeftColumn, Props };
