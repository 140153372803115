import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import Gem1 from '../../assets/gems/Gem_1.png';
import Gem2 from '../../assets/gems/Gem_2.png';
import Gem3 from '../../assets/gems/Gem_3.png';
import Gem4 from '../../assets/gems/Gem_4.png';
import Gem5 from '../../assets/gems/Gem_5.png';
import Gem6 from '../../assets/gems/Gem_6.png';

export interface WinStreakProps {
  amount: number;
}

const anim = keyframes`
  from, to {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;

  background-size: contain;
  background-repeat: no-repeat;

  transition: background-image 500ms ease-in-out;

  box-sizing: border-box;

  animation: ${anim} 1s;

  &.gem-1 {
    background-image: url(${Gem1});
    color: #feeca5;
  }
  &.gem-2 {
    background-image: url(${Gem2});
    color: #fff1d8;
  }
  &.gem-3 {
    background-image: url(${Gem3});
    color: #d7ffdd;
  }
  &.gem-4 {
    background-image: url(${Gem4});
    color: #fae2b9;
    padding-top: 0.3rem;
  }

  &.gem-5 {
    background-image: url(${Gem5});
    color: #d2effe;
  }

  &.gem-6 {
    background-image: url(${Gem6});
    color: #ffcfe9;
    padding-top: 0.5rem;
  }

  .value {
    flex: 1;
    text-align: center;
    font-size: 1.3rem;
  }
`;

export const WinStreak = (props: WinStreakProps) => {
  return (
    <Container
      key={`gem-${props.amount}`}
      className={`win-streak gem-${props.amount > 6 ? 6 : props.amount}`}
    >
      <div className="value">{props.amount}</div>
    </Container>
  );
};
