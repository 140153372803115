import { useEffect } from 'react';
import { useWebsocketContext } from '@shared/ui/contexts';
import { useAuthentication } from '@shared/ui/hooks';
import { LoginPage } from '@shared/ui/components/generic';
import { AppLayout } from './layouts/app-layout';

export const App = () => {
    const { getLocalAuthToken } = useAuthentication();
    const { updateAuthToken } = useWebsocketContext();

    const hasAuthToken =
        getLocalAuthToken !== null && getLocalAuthToken.length > 0;

    useEffect(() => {
        if (hasAuthToken) {
            updateAuthToken(getLocalAuthToken);
        }
    }, [getLocalAuthToken, hasAuthToken, updateAuthToken]);

    return hasAuthToken ? <AppLayout /> : <LoginPage />;
};
