import styled from '@emotion/styled';
import { SettingButton } from './setting-button';
import { ICONS } from '../icons';
import { useEffect, useState } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 5rem;
  gap: 1rem;

  pointer-events: auto;
`;

export interface SettingsButtonRowProps {
  muted: boolean;
  onSettingsClick?: () => void;
  onMuteClick?: () => void;
}

const SettingsButtonsRow = (props: SettingsButtonRowProps) => {
  const { muted, onMuteClick, onSettingsClick } = props;
  const [isFullScreen, setIsFullScreen] = useState(document.fullscreenElement);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      setIsFullScreen(document.fullscreenElement);
    });
  }, []);

  return (
    <Container>
      <SettingButton icon={ICONS.Setting} onClick={onSettingsClick} />
      <SettingButton icon={ICONS.Show} onClick={() => console.log('TODO')} />
      <SettingButton
        icon={muted ? ICONS.Mute : ICONS.Unmute}
        onClick={onMuteClick}
      />
      <SettingButton
        icon={isFullScreen ? ICONS.Minimize : ICONS.Fullscreen}
        onClick={toggleFullScreen}
      />
    </Container>
  );
};

export { SettingsButtonsRow };
