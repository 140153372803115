import styled from '@emotion/styled';

import { MobileButton } from './mobile-button';
import { MobileSVGButton } from './mobile-svg-button';
import { ICONS } from '@blackjack/ui/components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  /* border: 1px solid blue; */
  box-sizing: border-box;

  .left-column {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid blue; */
    box-sizing: border-box;
    padding: 2rem;

    .total-bet {
      display: flex;
      flex-direction: column;
    }

    .lobby {
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;
    }
  }

  .center {
    width: 50%;
    height: 100%;
    /* border: 1px solid blue; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .settings {
      width: 100%;
      height: 50%;
      /* border: 1px solid blue; */
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: end;

      .svg-button {
        margin: 0.5rem;
      }
    }

    .table-limits {
      width: 100%;
      height: 50%;
      /* border: 1px solid blue; */
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: start;
      margin-top: 1rem;

      .limits {
        color: #93c2e8;
        margin-left: 0.5rem;
      }
    }
  }

  .right-column {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    /* border: 1px solid blue; */
    box-sizing: border-box;
    padding: 2rem;

    .player-balance {
      display: flex;
      flex-direction: column;
    }

    .menu {
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;
    }
  }

  .label {
    font-size: 0.8rem;
    color: #ffffff;

    &.right {
      text-align: right;
    }
  }
  .value {
    font-size: 1.5rem;
    color: #93c2e8;
  }
`;

export const PlayerInfo = () => {
  const muted = false;
  return (
    <Container>
      <div className="left-column">
        <div className="total-bet">
          <span className="label">TOTAL BET</span>
          <span className="value">$15,999</span>
        </div>
        <div className="lobby">
          <MobileButton leftSide={true}>LOBBY</MobileButton>
        </div>
      </div>
      <div className="center">
        <div className="settings">
          <div className="svg-button">
            <MobileSVGButton icon={ICONS.Show} />
          </div>
          <div className="svg-button">
            <MobileSVGButton
              leftSide={false}
              icon={muted ? ICONS.Unmute : ICONS.Mute}
            />
          </div>
        </div>
        <div className="table-limits">
          <span>ANIMO BLACKJACK</span>
          <span className="limits">$50 - $500</span>
        </div>
      </div>
      <div className="right-column">
        <div className="player-balance">
          <span className="label right">BALANCE</span>
          <span className="value">$44,999</span>
        </div>
        <div className="menu">
          {}
          <MobileButton leftSide={false}>MENU</MobileButton>
        </div>
      </div>
    </Container>
  );
};
