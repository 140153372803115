import styled from '@emotion/styled';
import { SvgChip } from './svg-chip';

import Chip1 from '@shared/assets/audio/1chip.mp3';
import Chip5 from '@shared/assets/audio/5chip.mp3';
import Chip10 from '@shared/assets/audio/10chip.mp3';
import Chip25 from '@shared/assets/audio/25chip.mp3';
import Chip100 from '@shared/assets/audio/100chip.mp3';
import Chip500 from '@shared/assets/audio/500chip.mp3';

const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 0.4rem;
`;

const CHIP_COLORS = [
    '#5ebe61', // 10
    '#4dbb85', // 50
    '#56bfab',
    '#4481c4',
    '#4550a6',
    '#35268a',
];

const CHIP_SOUNDS = [Chip1, Chip5, Chip10, Chip25, Chip100, Chip500];

export interface ChipRowProps {
    betAmounts: number[];
    activeChip: number | null;
    totalCredits: number;
    setActiveChip: (amount: number) => void;
}

/**
 * Maps over the provided bet amounts to provide clickable chip UI.
 * This is limited by the hard coded chip colors. See CHIP_COLORS;
 *
 * @param props
 * @constructor
 */
export const ChipRow = (props: ChipRowProps) => {
    const { betAmounts, activeChip, totalCredits, setActiveChip } = props;

    const handleClick = (amount: number, idx: number) => {
        const sound = new Audio(CHIP_SOUNDS[idx]);
        sound.volume = 0.4;
        sound.play();
        setActiveChip(amount);
    };

    return (
        <Container>
            {betAmounts.map((amount: number, idx: number) => (
                <div
                    className="chip"
                    key={amount}
                    style={{ height: '7rem', width: '7rem' }}
                >
                    <SvgChip
                        value={amount.toString()}
                        color={CHIP_COLORS[idx]}
                        selected={amount === activeChip}
                        disabled={amount > totalCredits}
                        onClick={() => handleClick(amount, idx)}
                    />
                </div>
            ))}
        </Container>
    );
};
