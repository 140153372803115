import styled from '@emotion/styled';

import { ReactComponent as LogoSVG } from '../assets/animo-logo.svg';
import { keyframes } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';

export interface FullscreenLoaderProps {
    progress: number;
    minTime: number;
    isLoading: boolean;
    onFinish?: () => void;
}

const anim = keyframes`
  from {
    stroke-dashoffset: 5000;

    stroke: rgba(255,255,255,0.2);
  }
  to {
    stroke-dashoffset: 0;
    stroke: rgba(255,255,255,0.5);
  }
`;

const Wrapper = styled.div<{ progress: number; time: number }>`
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 1);
    backdrop-filter: blur(1rem);

    gap: 4rem;
    z-index: 20;

    .logo {
        width: 40rem;
        position: relative;
    }

    .foreground {
        svg {
            path {
                stroke-width: 0.25rem;
                stroke-dasharray: 800;
                stroke-dashoffset: 0;
                fill: none;

                animation: ${anim} 10s infinite alternate;
            }
        }
    }

    .background {
        position: absolute;
        opacity: 1;

        width: 100%;
        height: 100%;

        top: 0;

        svg {
            path {
                fill: rgba(255, 255, 255, 0.1);
                stroke: none;
            }
        }
    }

    transition: backdrop-filter 500ms ease-in, background-color 500ms ease-in,
        opacity 500ms ease-in;

    &.finished {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: none;

        pointer-events: none;

        opacity: 0;

        .foreground,
        .background {
            opacity: 0;

            transition: opacity 500ms ease-in;
        }
    }

    .loading-bar {
        width: 30rem;
        height: 0.4rem;

        border-radius: 0.1rem;
        background-color: rgba(255, 255, 255, 0.2);

        box-sizing: border-box;

        overflow: hidden;

        .bar-foreground {
            background-color: rgba(255, 255, 255, 1);
            box-sizing: border-box;
            height: 0.4rem;
            margin-top: 0.1rem;
            width: calc(100% * ${(props) => props.progress});
            transform-origin: left center;
            border-radius: 0.25rem;
            transition: width ${(props) => props.time}ms ease-in;
        }
    }
`;

export const FullscreenLoader = (props: FullscreenLoaderProps) => {
    const { onFinish } = props;

    const [progress, setProgress] = useState(0);
    const [finished, setFinished] = useState(false);
    const time = useRef(2500);

    useEffect(() => {
        setProgress((p) => {
            const diff = Math.abs(p - props.progress);
            time.current = diff * 2500;

            return props.progress;
        });
    }, [props.progress]);

    useEffect(() => {
        if (!props.isLoading) {
            setFinished(false);
            const timer = setTimeout(() => {
                setFinished(true);
            }, 500);

            return () => {
                clearTimeout(timer);
            };
        } else {
            time.current = 0;
            setFinished(false);
        }
    }, [props.isLoading]);

    useEffect(() => {
        if (finished && onFinish) onFinish();
    }, [finished, onFinish]);

    return (
        <Wrapper
            progress={
                progress * 0.75 +
                (finished ? 0.25 : props.isLoading ? 0.0 : 0.15)
            }
            time={time.current}
            className={finished ? 'finished' : ''}
        >
            <div className="logo">
                <div className="foreground">
                    <LogoSVG />
                </div>
                <div className="background">
                    <LogoSVG />
                </div>
            </div>
            <div className="loading-bar">
                <div className="bar-foreground"></div>
            </div>
        </Wrapper>
    );
};
