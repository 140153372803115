import {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import { io, Socket } from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';

import { serviceToken } from '@rgs/schemas/game-services';
import { log } from '@shared/utils';

import {
    BaseEvents,
    ErrorEvent,
    IBaseEvent,
    ResponseEvent,
} from '@shared/events';

type GameSocket = Socket & {
    emitWithResponse: (
        event: IBaseEvent<string, unknown>
    ) => Promise<ResponseEvent>;
};

interface WebSocketContextProps {
    updateAuthToken: (token: string) => void;
    socket: GameSocket;
    connected: boolean;
    duplicateSession: boolean;
}

const WebsocketContext = createContext<WebSocketContextProps | undefined>(
    undefined
);

interface WebSocketProviderProps {
    children: ReactNode;
    token?: string;
    // Use url embedded into token
    useTokenHost?: boolean;
}

const createSocket = (url = '') => {
    const socket = io(url, { autoConnect: false }) as GameSocket;

    socket.emitWithResponse = function (
        event: IBaseEvent<string, unknown>,
        timeout = 5000
    ) {
        const eventName = event[0];
        const data = event[1];

        return new Promise((resolve, reject) => {
            let timer: ReturnType<typeof setTimeout> | null = null;

            this.emit(eventName, data);

            const onResponse = (response: ResponseEvent) => {
                if (response.message_id === data.message_id) {
                    this.off(BaseEvents.Response, onResponse);
                    if (timer) clearTimeout(timer);
                    resolve(response);
                }
            };

            timer = setTimeout(() => {
                this.off(BaseEvents.Response, onResponse);
                reject('TIMEOUT_ERROR');
            }, timeout);

            this.on(BaseEvents.Response, onResponse);
        });
    };
    return socket;
};

const getHostURL = (token?: string, useTokenHost = false) => {
    if (useTokenHost && token && token.length) {
        if (useTokenHost) {
            const decoded = jwtDecode(token);

            const { success, data } = serviceToken.safeParse(decoded);

            if (success) {
                if (data.host) {
                    return data.host;
                }
            }
        }
    }
    return process.env.NODE_ENV === 'development' ? 'localhost:3119' : '';
};

export const WebSocketProvider = (props: WebSocketProviderProps) => {
    const { children, token, useTokenHost } = props;
    const [authToken, setAuthToken] = useState(token ? token : '');
    const [connected, setConnected] = useState(false);
    const [duplicateSession, setDuplicateSession] = useState(false);

    const socket = useRef<GameSocket>(
        createSocket(getHostURL(token, useTokenHost))
    ).current;

    useEffect(() => {
        if (authToken.length) {
            log.socket('Setting Auth Token', authToken);
            socket.auth = (cb) => {
                cb({ token: authToken });
            };

            socket.on('connect', () => {
                log.socket('Connected');
                setConnected(true);
                setDuplicateSession(false);
            });
            socket.on('disconnect', () => {
                log.socket('disconnected');
                setConnected(false);
            });
            socket.on('connect_error', (err) => {
                log.socket('Error:', err);
                setConnected(false);
            });

            log.socket('Connecting...');

            socket.connect();

            socket.on(BaseEvents.Error, (ev: ErrorEvent) => {
                console.log(ev);
                if (ev.payload.code === 401) {
                    // Do this better
                    window.localStorage.removeItem('auth_token');
                }
            });

            //Handle duplicate session event trigger
            socket.on(BaseEvents.DuplicateSession, () => {
                log.socket('DuplicateSession');
                setConnected(false);
                setDuplicateSession(true);
            });

            return () => {
                socket.off('connect');
                socket.off('disconnect');
                socket.off('connect_error');
                socket.off(BaseEvents.DuplicateSession);
                socket.off(BaseEvents.Error);
                socket.close();

                setConnected(false);
                setDuplicateSession(false);
            };
        }
    }, [authToken, useTokenHost, socket]);

    const updateAuthToken = useCallback((token: string) => {
        setAuthToken(token);
    }, []);

    return (
        <WebsocketContext.Provider
            value={{
                connected,
                updateAuthToken,
                socket,
                duplicateSession,
            }}
        >
            {children}
        </WebsocketContext.Provider>
    );
};

export const useWebsocketContext = (): WebSocketContextProps => {
    const context = useContext(WebsocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a WebSocketProvider');
    }
    return context;
};
