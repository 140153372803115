import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { injectGlobal } from '@emotion/css';

const OuterWrapper = styled.div`
  position: relative;

  max-width: 43rem;
  height: 93.2rem;

  flex: 1;

  overflow: hidden;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .avitar-image {
    width: 100%;
    height: 33.5rem;
    background-image: url('./../../assets/UI_Latest_mobile_avitar.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    position: absolute;
  }

  .virtual-chips {
    width: 100%;
    height: 33.5rem;
    background-image: url('./../../assets/UI_Latest_mobile_1.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    position: absolute;
  }

  .table-overlay {
    width: 100%;
    height: 100%;
    background-image: url('./../../assets/UI_mobile_table_vector.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    position: absolute;
  }
`;

type Props = PropsWithChildren;

const MobileAspectRatioKeeper = (props: Props) => {
  const { children } = props;

  injectGlobal`
      html, body {
        font-size: calc((min(100vh, 932px) / 932) * 10);
      }
    `;

  return (
    <OuterWrapper>
      <InnerWrapper>
        <div className="avitar-image"></div>
        <div className="virtual-chips"></div>
        <div className="table-overlay"></div>
        {children}
      </InnerWrapper>
    </OuterWrapper>
  );
};

export { MobileAspectRatioKeeper, Props };
