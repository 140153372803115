import { useWindowSize } from '@shared/ui/hooks';

import { MobileAspectRatioKeeper } from '../components/mobile/mobile-aspect-ratio-keeper';
import { AspectRatioKeeper } from '@shared/ui/components/generic';

import backgroundImage from '@shared/assets/images/UI_DEMO_EXAMPLE 1.png';

import { DesktopAppLayout } from './app-layout-desktop';
import { MobileAppLayout } from './app-layout-mobile';

export const AppLayout = () => {
    const [width, height] = useWindowSize();

    const isVerticle = width < height;

    return isVerticle ? (
        <MobileAspectRatioKeeper>
            <MobileAppLayout />
        </MobileAspectRatioKeeper>
    ) : (
        <AspectRatioKeeper backgroundImage={backgroundImage}>
            <DesktopAppLayout />
        </AspectRatioKeeper>
    );
};
