import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useWebsocketContext } from '@shared/ui/contexts';

import {
  BlackJackStates,
  createPlayerActionEvent,
  createPlayerBetsEvent,
  createPlayerDealNowEvent,
  createPlayerInsuranceEvent,
  createPlayerSitEvent,
  Events,
  GameInfoEvent,
  GameState,
  GameStateEvent,
  InsuranceOfferEvent,
  PlayerAction,
  PlayerBet,
  PlayerData,
  PlayersEvent,
  PlayerTurnEvent,
  RoundAction,
  RoundActionEvent,
  RoundEndEvent,
  RoundStartEvent,
} from '@blackjack/models';

import { log } from '@shared/utils';
import { BaseEvents, IBaseEvent, UserEvent } from '@shared/events';
import {
  ClearPreActionEvent,
  createClearPreActionEvent,
  FrontendEvents,
} from './types/frontend-events';
import toast from 'react-hot-toast';

export interface GameContextProps {
  gameState: GameState;
  seatIsFree: (seatId: number) => boolean;
  handlePlayerSit: (seatId: number) => void;
  isOwnSeat: (seatId: number) => boolean;
  handlePlayerBets: (
    bets: { seat: number; amount: number; type: PlayerBet }[]
  ) => void;
  handlePlayerAction: (
    action: PlayerAction,
    seatId: number,
    isPreAction?: boolean
  ) => void;
  handlePlayerInsurance: (insurance: boolean) => void;
  handlePlayerDealNow: () => void;
  getPlayerById: (userId: string) => PlayerData | undefined;

  muted: boolean;
  previousWin: number;
  setMuted: (value: boolean) => void;
  toggleMute: () => void;
}

interface GameProviderProps {
  children: ReactNode;
}

const GameContext = createContext<GameContextProps | undefined>(undefined);

export const initialState: GameState = {
  user: null,
  userSeatIds: [],
  players: [],
  dealer: null,
  gameInfo: null,
  roundInfo: null,
  currentState: {
    next_state: BlackJackStates.WaitingForPlayers,
    previous_state: BlackJackStates.WaitingForPlayers,
    seat_id: -1,
    hand: 0,
  },
  currentAction: null,
  playerPreActions: [],
  insuranceInfo: null,
};

type GameReducer = (
  state: GameState,
  event: IBaseEvent<Events | BaseEvents | FrontendEvents, unknown>[1]
) => GameState;

const gameReducer: GameReducer = (state, event): GameState => {
  switch (event.event) {
    case BaseEvents.User: {
      const eventData = event as UserEvent;
      return {
        ...state,
        user: eventData.payload.user,
      };
    }
    case Events.Players: {
      const eventData = event as PlayersEvent;
      const currentPlayerSeats = eventData.payload.players.reduce(
        (acc: number[], player) => {
          if (player.user_id === state?.user?.id) {
            acc.push(player.seat);
          }
          return acc;
        },
        []
      );

      return {
        ...state,
        userSeatIds: currentPlayerSeats,
        dealer: eventData.payload.dealer,
        players: eventData.payload.players,
      };
    }
    case Events.GameInfo: {
      const eventData = event as GameInfoEvent;
      return {
        ...state,
        gameInfo: eventData.payload,
      };
    }
    case Events.RoundStart: {
      const eventData = event as RoundStartEvent;
      return {
        ...state,
        roundInfo: eventData.payload,
      };
    }
    case Events.OfferInsurance: {
      const eventData = event as InsuranceOfferEvent;
      return {
        ...state,
        insuranceInfo: eventData.payload,
      };
    }
    case Events.GameState: {
      const eventData = event as GameStateEvent;
      return {
        ...state,
        currentState: eventData.payload,
      };
    }
    case Events.PlayerTurn: {
      const eventData = event as PlayerTurnEvent;
      return {
        ...state,
        currentAction: eventData.payload,
      };
    }
    case Events.RoundActions: {
      const eventData = event as RoundActionEvent;
      const currentPlayerActions = eventData.payload.actions.reduce(
        (acc: RoundAction[], action: RoundAction) => {
          if (state.userSeatIds.includes(+action.seat_id)) {
            acc.push(action);
          }
          return acc;
        },
        []
      );
      return {
        ...state,
        playerPreActions: currentPlayerActions,
      };
    }
    case FrontendEvents.CLEAR_PRE_ACTION: {
      const eventData = event as ClearPreActionEvent;
      return {
        ...state,
        playerPreActions: state.playerPreActions.filter(
          (action: RoundAction) => action.seat_id !== eventData.payload.seat_id
        ),
      };
    }
    case Events.RoundEnd: {
      const eventData = event as RoundEndEvent;
      return {
        ...initialState,
        gameInfo: state.gameInfo,
        roundInfo: eventData.payload,
        currentState: state.currentState,
      };
    }
    default:
      return state;
  }
};

export const GameContextProvider = (props: GameProviderProps) => {
  const { children } = props;
  const { socket } = useWebsocketContext();
  const [gameState, dispatch] = useReducer(gameReducer, initialState);
  const [muted, setMuted] = useState(false);
  const [previousWin, setPreviousWin] = useState(0);

  const handlePlayerSit = useCallback(
    (seatId: number) => {
      const event = createPlayerSitEvent(seatId);

      log.socket('Player sitting', event);
      socket.emitWithResponse(event).then((response) => {
        if (response.payload.status > 400) {
          toast(response.payload.message);
        }
      });
    },
    [socket]
  );

  const handlePlayerBets = useCallback(
    (bets: { seat: number; amount: number; type: PlayerBet }[]) => {
      const amount = bets.reduce((acc, val) => acc + val.amount, 0);
      if (amount > 0) {
        setPreviousWin(0);
      }

      const event = createPlayerBetsEvent(bets);
      log.socket('PlayerBets', event);
      socket.emitWithResponse(event).then((response) => {
        if (response.payload.status > 400) {
          toast(response.payload.message);
        }
      });
    },
    [socket]
  );

  const handlePlayerAction = useCallback(
    (action: PlayerAction, seatId: number, isPreAction = false) => {
      if (gameState.currentAction) {
        const event = createPlayerActionEvent(action, seatId);

        log.socket('PlayerAction', event);
        socket.emitWithResponse(event).then((response) => {
          if (response.payload.status > 400) {
            toast(response.payload.message);
          }
        });

        if (isPreAction) {
          const evt = createClearPreActionEvent(seatId);
          console.log('FIRING PRE ACTION', evt);
          dispatch(evt);
        }
      }
    },
    [gameState.currentAction, socket]
  );

  const handlePlayerInsurance = useCallback(
    (insurance: boolean) => {
      const event = createPlayerInsuranceEvent(insurance);
      log.socket('Setting Insurance');
      socket.emitWithResponse(event).then((response) => {
        if (response.payload.status > 400) {
          toast(response.payload.message);
        }
      });
    },
    [socket]
  );

  const handlePlayerDealNow = useCallback(() => {
    const event = createPlayerDealNowEvent();
    log.socket('Player Deal Now');
    socket.emitWithResponse(event).then((response) => {
      if (response.payload.status > 400) {
        toast(response.payload.message);
      }
    });
  }, [socket]);

  const seatIsFree = useCallback(
    (seatId: number) => {
      return !gameState.players.find((p) => p.seat === seatId);
    },
    [gameState.players]
  );

  const isOwnSeat = useCallback(
    (seatId: number) => {
      if (gameState.user) {
        return !!gameState.userSeatIds.includes(seatId);
      }
      return false;
    },
    [gameState.user, gameState.userSeatIds]
  );

  const getPlayerById = useCallback(
    (userId: string) => {
      return gameState.players.find((player) => player.user_id === userId);
    },
    [gameState.players]
  );

  useEffect(() => {
    socket.on(Events.GameInfo, (gameInfo) => {
      log.socket(Events.GameInfo);
      dispatch(gameInfo);
    });

    socket.on(Events.RoundStart, (roundStartEvent) => {
      log.socket(Events.RoundStart);
      dispatch(roundStartEvent);
    });

    socket.on(Events.OfferInsurance, (offerInsurance) => {
      log.socket(Events.OfferInsurance);
      dispatch(offerInsurance);
    });

    socket.on(Events.Players, (playerEvent) => {
      log.socket(Events.Players);
      dispatch(playerEvent);
    });

    socket.on(Events.GameState, (gmeState) => {
      log.socket(Events.GameState);
      dispatch(gmeState);
    });

    socket.on(Events.PlayerTurn, (playerTurn) => {
      log.socket(Events.PlayerTurn);
      dispatch(playerTurn);
    });

    socket.on(Events.RoundActions, (roundActions: RoundActionEvent) => {
      log.socket(Events.RoundActions);
      dispatch(roundActions);
    });

    socket.on(BaseEvents.User, (usrEvent: UserEvent) => {
      log.socket(BaseEvents.User);
      dispatch(usrEvent);
    });

    socket.on(Events.RoundEnd, (roundEnd) => {
      log.socket(Events.RoundEnd, roundEnd);
      dispatch(roundEnd);
      if (roundEnd.payload.total_won > 0) {
        setPreviousWin(roundEnd.payload.total_won);
      }
    });

    return () => {
      socket.off(Events.GameInfo);
      socket.off(Events.RoundStart);
      socket.off(Events.OfferInsurance);
      socket.off(Events.Players);
      socket.off(Events.GameState);
      socket.off(Events.PlayerTurn);
      socket.off(Events.RoundActions);
      socket.off(BaseEvents.User);
      socket.off(Events.RoundEnd);
    };
  }, [socket]);

  const toggleMute = useCallback(() => {
    setMuted((m) => !m);
  }, []);

  const value = useMemo(() => {
    return {
      handlePlayerSit,
      handlePlayerBets,
      handlePlayerAction,
      handlePlayerInsurance,
      handlePlayerDealNow,

      seatIsFree,
      isOwnSeat,
      getPlayerById,

      gameState,

      muted,
      previousWin,
      setMuted,
      toggleMute,
    };
  }, [
    handlePlayerSit,
    handlePlayerBets,
    handlePlayerAction,
    handlePlayerInsurance,
    handlePlayerDealNow,

    seatIsFree,
    isOwnSeat,
    getPlayerById,

    gameState,
    muted,
    previousWin,
    setMuted,
    toggleMute,
  ]);

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

export const useGameContext = (): GameContextProps => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error('useSocket must be used within a WebSocketProvider');
  }
  return context;
};
