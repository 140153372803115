import { useCallback } from 'react';
import { log } from '@shared/utils';

interface AuthResponse {
    success: boolean;
    auth: {
        token: string;
    };
}

interface AuthenticationHook {
    getLocalAuthToken: string | null;
    authenticate: (
        username: string,
        password: string
    ) => Promise<string | null>;
    clearAuthentication: () => void;
}

const AUTH_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.NX_PUBLIC_AGGREGATOR_URL
        : `/auth-api`;

export const useAuthentication = (): AuthenticationHook => {
    const getLocalAuthToken = window.localStorage.getItem('auth_token') || null;

    const authenticate = useCallback(
        async (username: string, password: string) => {
            try {
                const response = await fetch(`${AUTH_URL}/login`, {
                    mode: 'cors',
                    method: 'POST',
                    body: JSON.stringify({
                        username: username,
                        password: password,
                    }),

                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const json: AuthResponse = await response.json();
                log.info('Remote Auth Response', json);

                if (json.success) {
                    const newToken = json.auth.token;
                    window.localStorage.setItem('auth_token', newToken);
                    return newToken;
                } else {
                    console.log('Login error');
                }
            } catch (error) {
                console.error('Authentication error:', error);
            }
            return null;
        },
        []
    );

    const clearAuthentication = useCallback(() => {
        window.localStorage.removeItem('auth_token');
    }, []);

    return { getLocalAuthToken, authenticate, clearAuthentication };
};
