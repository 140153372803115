import styled from '@emotion/styled';
import { ICard, IPlayerScore } from '@blackjack/models';
import { keyframes } from '@emotion/react';
import { Card, Score } from '@blackjack/ui/components';

const anim = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const HandRow = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;

  transition: width 200ms ease-in-out;

  .card {
    margin-right: -3.8rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.9);

    animation: ${anim} 250ms ease-in-out;
  }

  .card:last-child {
    margin-right: 0;
  }
  .score {
    margin-right: 2rem;
  }
`;

interface Props {
  score?: IPlayerScore;
  hand: ICard[];
}

const CardHandMapper = (props: Props) => {
  const { hand, score } = props;

  return (
    <HandRow>
      {score && <Score score={score} />}
      {hand.map((card) => (
        <Card
          key={card.uuid}
          face={card.face}
          value={card.value}
          flipped
          style={{
            position: 'relative',
          }}
        />
      ))}
    </HandRow>
  );
};

export { CardHandMapper, Props };
