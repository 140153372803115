import * as THREE from 'three';
import { Color, DoubleSide, MathUtils, MeshStandardMaterial } from 'three';
import { useEffect, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';

export interface AnimatedShapeProps {
  scale?: number;
  color?: string;
  opacity?: number;
  pulsate?: boolean;
  texturePath?: string;
}

export const AnimatedShape = (props: AnimatedShapeProps) => {
  const {
    scale = 1,
    opacity = 1.0,
    color = 'white',
    pulsate = false,
    texturePath = '',
  } = props;

  const { gl } = useThree();

  const [desiredOpacity, setDesiredOpacity] = useState(opacity);

  const texture = useTexture(texturePath);
  texture.flipY = false;
  texture.anisotropy = gl.capabilities.getMaxAnisotropy();
  texture.minFilter = THREE.LinearFilter;

  const mat = useRef(
    new MeshStandardMaterial({
      color,
      side: DoubleSide,
      depthWrite: false,
      opacity,
      transparent: true,
      fog: false,
      map: texture,
    })
  );

  useEffect(() => {
    setDesiredOpacity(opacity);
  }, [opacity]);

  useFrame((state) => {
    if (pulsate) {
      mat.current.opacity = MathUtils.lerp(
        0.6,
        1.0,
        Math.sin(state.clock.getElapsedTime() * 3)
      );
    } else {
      mat.current.opacity = MathUtils.lerp(
        mat.current.opacity,
        desiredOpacity,
        0.05
      );
    }
    mat.current.color.lerp(new Color(color), 0.05);
  });

  return (
    <mesh scale={scale} material={mat.current}>
      <planeGeometry args={[1, 1]} />
    </mesh>
  );
};
