import styled from '@emotion/styled';

import {
  useGameContext,
  useLogicStateContext,
  useLogicActionContext,
} from '@blackjack/ui/contexts';

import { Modal } from './modal';

import { ReactComponent as DoubleSVG } from '../../assets/buttons/Double_Button.svg';
import { ReactComponent as RepeatSVG } from '../../assets/buttons/Repeat_Button.svg';
import { ReactComponent as UndoSVG } from '../../assets/buttons/Undo_Button.svg';

import { SvgButton } from '../buttons';
import { ChipRow } from '../chips';
import { Timer } from '../timer';

import { DealCardsButton } from '../buttons/deal-cards-button';

const StyledModal = styled(Modal)`
  height: 23rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;

  padding-bottom: 3rem;

  h2 {
    text-transform: uppercase;
    font-size: 2.4rem;
    color: #ffffff;
    margin-top: 2rem;
  }

  .button-interaction-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .bet-undo-button {
      /* Margin adjusts for bet double button text offset */
      margin-top: 0.8rem;
      width: 4.4rem;
      height: 2.8rem;
    }

    .bet-double-button {
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      .button-text {
        font-size: 0.8rem;
      }

      svg {
        width: 4.2rem;
        height: 3rem;
      }
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 0.4rem;
  }
`;

const DealCardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  position: absolute;

  margin-top: -1.65rem;

  .clicky {
    pointer-events: auto;
    cursor: pointer;
    user-select: none;
  }

  .content {
    padding: 1rem 2rem;
    color: white;
    font-family: Chakra Petch;
    font-size: 1.4rem;
  }
`;

export const PlayerBetModal = () => {
  const {
    totalCredits,
    activeChip,
    canRepeat,
    userTotalSeats,
    baseBetTotal,
    sideBetTotals,
  } = useLogicStateContext();

  const { setActiveChip, undoBet, repeatLast, doubleBets } =
    useLogicActionContext();

  const { gameState, handlePlayerDealNow } = useGameContext();

  const endTime =
    (gameState.roundInfo?.start_time || 0) +
    (gameState.roundInfo?.duration || 0);

  const betAmounts = gameState.gameInfo?.bet_amounts || [];

  const canDealNow =
    gameState.players.every((p) => p.user_id === gameState?.user?.id) &&
    baseBetTotal > 0;

  const canDouble =
    (gameState.user?.credits || 0) >=
      (baseBetTotal * userTotalSeats + sideBetTotals) * 2 &&
    baseBetTotal * 2 <= (gameState.gameInfo?.max_bet || 0);

  return (
    <StyledModal>
      <Container>
        <Timer endTime={endTime} />
        <div className="button-interaction-row">
          <div className="button-wrapper">
            <SvgButton
              onClick={undoBet}
              disabled={false}
              className={'bet-undo-button'}
            >
              <UndoSVG />
            </SvgButton>
            <ChipRow
              betAmounts={betAmounts}
              activeChip={activeChip}
              totalCredits={totalCredits}
              setActiveChip={setActiveChip}
            />
            {baseBetTotal > 0 ? (
              <SvgButton
                onClick={doubleBets}
                disabled={!canDouble}
                className={'bet-double-button'}
              >
                <span className={'button-text'}>Double</span>
                <DoubleSVG />
              </SvgButton>
            ) : (
              <SvgButton
                onClick={repeatLast}
                disabled={!canRepeat}
                className={'bet-double-button'}
              >
                <span className={'button-text'}>Repeat</span>
                <RepeatSVG />
              </SvgButton>
            )}
          </div>
        </div>
        <h2>Place Your Bets</h2>
      </Container>
      {canDealNow && (
        <DealCardsContainer>
          <DealCardsButton onClick={handlePlayerDealNow} />
        </DealCardsContainer>
      )}
    </StyledModal>
  );
};
