import { PlayerBet } from '@blackjack/models';
import { useEffect, useMemo, useState } from 'react';
import { Chip } from './chip';

import multiChips from '../../assets/audio/multi-chip.mp3';

interface ChipType {
  uuid: string;
  amount: number;
  added: boolean;
  removed: boolean;
  type: string;
}

export interface ChipsProps {
  scale: number;
  position: [number, number, number];
  rotateZ: number;
  type: PlayerBet;
  seat: number;
  seated: boolean;
  ownSeat: boolean;
  bets: {
    uuid: string;
    amount: number;
    type: PlayerBet;
    seat: number;
  }[];
  amount: number;
  winAmount: number;
  mode: 'BASE' | 'SIDEBETS' | 'EDIT' | 'LOCKED' | 'CLEAR';
  muted: boolean;
}

const calculateChipTotal = (chips: ChipType[]) =>
  chips.reduce((a, c) => {
    if (c.added && !c.removed) return a + c.amount;
    return a;
  }, 0);

const audio = new Audio(multiChips);

export const Chips = (props: ChipsProps) => {
  const {
    scale,
    position,
    rotateZ,
    amount,
    bets,
    mode,
    seat,
    seated,
    ownSeat,
    type,
    winAmount,
    muted,
  } = props;

  const [chips, setChips] = useState<ChipType[]>([]);
  const [hidden, setHidden] = useState(false);

  const handleRemove = (uuid: string) => {
    return () => {
      setChips((ch) => {
        const nn = ch
          .map((c) => {
            if (c.uuid === uuid) {
              return { ...c, added: true };
            }
            return { ...c };
          })
          .filter((c) => !(c.added && c.removed));
        return nn;
      });
    };
  };

  const total = useMemo(() => {
    if (!ownSeat || mode !== 'EDIT') {
      return amount;
    }
    return calculateChipTotal(chips);
  }, [chips, amount, ownSeat, mode]);

  const chipDirection = useMemo(() => {
    if (
      type === PlayerBet.BASE ||
      type === PlayerBet.INSURANCE ||
      type === PlayerBet.BEHIND
    ) {
      if (mode === 'BASE') return 'to-dealer';
    } else {
      if (mode === 'SIDEBETS') return 'to-dealer';
    }
    return winAmount > 0 ? 'to-player' : 'to-dealer';
  }, [winAmount, mode, type]);

  const chipVisible = useMemo(() => {
    if (
      type === PlayerBet.BASE ||
      type === PlayerBet.INSURANCE ||
      type === PlayerBet.BEHIND
    )
      return mode !== 'CLEAR';
    // Remove sidebets after first 2 cards dealt if you didn't win
    if (mode === 'SIDEBETS' && winAmount <= 0) return false;
    if (mode === 'CLEAR') return false;
    return true;
  }, [mode, winAmount, type]);

  useEffect(() => {
    if (!seated) setChips([]);
    if (mode !== 'EDIT') setChips([]);
    if (mode === 'EDIT' && winAmount === 0) setHidden(false);
  }, [seated, mode, winAmount]);

  useEffect(() => {
    if (mode === 'CLEAR' && chipDirection === 'to-player' && !muted) {
      audio.volume = 0.3;
      audio.play();
    }
  }, [chipDirection, mode, muted]);

  useEffect(() => {
    setChips((b) => {
      const prevIds = b.map((c) => c.uuid);
      const newIds = bets.map((c) => c.uuid);
      const removed = b.filter((c) => !newIds.includes(c.uuid));
      const added = bets.filter((c) => !prevIds.includes(c.uuid));
      const removedIds = removed.map((c) => c.uuid);
      return [
        ...b.filter((c) => !removedIds.includes(c.uuid)),
        ...removed.map((c) => ({ ...c, added: false, removed: true })),
        ...added.map((c) => ({
          ...c,
          added: seat !== c.seat,
          removed: false,
          type: c.type,
        })),
      ];
    });
  }, [bets, seat]);

  return (
    <group scale={scale}>
      {seated && winAmount > 0 && !hidden && (
        <Chip
          amount={winAmount}
          isAnimated={props.ownSeat}
          isVisible={chipVisible}
          direction={chipDirection}
          position={[position[0], position[1], position[2] - 0.023]}
          rotateZ={rotateZ}
          muted={muted}
          chipType={type === PlayerBet.BASE ? 'base' : 'side'}
        />
      )}
      {seated && total > 0 && !hidden && (
        <Chip
          amount={total}
          isAnimated={!chipVisible && props.ownSeat}
          isVisible={chipVisible}
          direction={chipDirection}
          position={position}
          onFinish={() => setHidden(true)}
          rotateZ={rotateZ}
          muted={muted}
          chipType={type === PlayerBet.BASE ? 'base' : 'side'}
        />
      )}
      {seated &&
        mode === 'EDIT' &&
        chips
          .filter((c) => !c.added)
          .map((c) => (
            <Chip
              amount={c.amount}
              isAnimated={true}
              isVisible={!c.removed}
              key={c.uuid}
              position={position}
              onFinish={handleRemove(c.uuid)}
              rotateZ={rotateZ}
              muted={muted}
              chipType={type === PlayerBet.BASE ? 'base' : 'side'}
            />
          ))}
    </group>
  );
};
