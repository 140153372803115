import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ChatContextProvider, WebSocketProvider } from '@shared/ui/contexts';
import {
    GameContextProvider,
    LogicContextProvider,
} from '@blackjack/ui/contexts';

import { App } from './app/app';

// App default styles
import '@shared/assets/styles/style.css';

import './sentry.config';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <StrictMode>
        <BrowserRouter>
            <WebSocketProvider>
                <GameContextProvider>
                    <ChatContextProvider>
                        <LogicContextProvider>
                            <App />
                        </LogicContextProvider>
                    </ChatContextProvider>
                </GameContextProvider>
            </WebSocketProvider>
        </BrowserRouter>
    </StrictMode>
);
