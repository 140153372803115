import styled from '@emotion/styled';

import { ReactComponent as DoubleLarge } from '../../assets/buttons/Double_Button_Large.svg';
import { ReactComponent as HitLarge } from '../../assets/buttons/Hit_Button.svg';
import { ReactComponent as StandLarge } from '../../assets/buttons/Stand_Button.svg';
import { ReactComponent as SplitLarge } from '../../assets/buttons/Split_Button_Large.svg';

import { ReactComponent as DoubleSmall } from '../../assets/buttons/Small_Double_Button.svg';
import { ReactComponent as SmallHit } from '../../assets/buttons/Small_Hit_Button.svg';
import { ReactComponent as SmallStand } from '../../assets/buttons/Small_Stand_Button.svg';
import { ReactComponent as SplitSmall } from '../../assets/buttons/Small_Split_Button.svg';

import { SvgButton } from './svg-button';
import { PlayerAction } from '@blackjack/models';
import { CSSProperties } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2%;
  align-items: center;
  justify-content: center;

  pointer-events: auto;

  .side-button-large {
    width: 10rem;
    height: 4rem;
  }

  .hit-button-large {
    width: 14.6rem;
    height: 7rem;
  }

  .side-button-small {
    width: 2.4rem;
    margin-bottom: 0.4rem;

    svg {
      .st0 {
        stroke: #ffffff;
      }
    }
  }

  .hit-button-small {
    width: 4rem;

    svg {
      .st0 {
        stroke: #ffffff;
      }
    }
  }
`;

export interface HitButtonRowProps {
  seatId: number;
  availablePlayerAction: PlayerAction[];
  handlePlayerAction: (
    action: PlayerAction,
    seatId: number,
    isPreAction: boolean
  ) => void;
  isMini?: boolean;
  style?: CSSProperties;
}

export const HitButtonRow = (props: HitButtonRowProps) => {
  const {
    isMini = false,
    seatId,
    availablePlayerAction,
    handlePlayerAction,
    style,
  } = props;

  const isActive = (action: PlayerAction) => {
    return availablePlayerAction.includes(action);
  };

  const buttonClass = isMini ? 'small' : 'large';

  return (
    <Container style={style}>
      <SvgButton
        onClick={() => handlePlayerAction(PlayerAction.DOUBLE, seatId, isMini)}
        disabled={!isActive(PlayerAction.DOUBLE)}
        className={`side-button-${buttonClass}`}
      >
        {isMini ? <DoubleSmall /> : <DoubleLarge />}
      </SvgButton>
      <SvgButton
        onClick={() => handlePlayerAction(PlayerAction.HIT, seatId, isMini)}
        disabled={!isActive(PlayerAction.HIT)}
        className={`hit-button-${buttonClass}`}
      >
        {isMini ? <SmallHit /> : <HitLarge />}
      </SvgButton>
      <SvgButton
        onClick={() => handlePlayerAction(PlayerAction.STAND, seatId, isMini)}
        disabled={!isActive(PlayerAction.STAND)}
        className={`hit-button-${buttonClass}`}
      >
        {isMini ? <SmallStand /> : <StandLarge />}
      </SvgButton>
      <SvgButton
        onClick={() => handlePlayerAction(PlayerAction.SPLIT, seatId, isMini)}
        disabled={!isActive(PlayerAction.SPLIT)}
        className={`side-button-${buttonClass}`}
      >
        {isMini ? <SplitSmall /> : <SplitLarge />}
      </SvgButton>
    </Container>
  );
};
