import styled from '@emotion/styled';

import { CornerBox } from '@blackjack/ui/components';
import { keyframes } from '@emotion/react';

const anim = keyframes`
  from {
    opacity: 0;
    transform: translateY(20rem) scale(0.1);
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: fixed;
  z-index: 10001;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 192rem;
  height: 108rem;

  margin-left: calc(-192rem / 2);
  left: 50%;
  top: 0;

  pointer-events: none;

  animation: ${anim} 500ms;

  &.hide {
    display: none;
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 4rem 4rem;

    pointer-events: none;

    .button {
      height: 5rem;
    }
  }

  .message {
    font-size: 2rem;
  }
`;

const Wrapper = styled.div`
  width: 45rem;
`;

export interface CustomPopUpProps {
  isVisible: boolean;
  onClose: () => void;
  message: string;
}

export const CustomPopUp = (props: CustomPopUpProps) => {
  const { isVisible, onClose, message } = props;
  return (
    <Container onClick={onClose} className={isVisible ? 'show' : 'hide'}>
      <Wrapper>
        <CornerBox topRight bottomLeft topLeft bottomRight cornerSize={'2rem'}>
          <div className="form">
            <span className="message">{message}</span>
          </div>
        </CornerBox>
      </Wrapper>
    </Container>
  );
};
