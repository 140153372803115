import styled from '@emotion/styled';
import { useGameContext } from '@blackjack/ui/contexts';
import { CornerBox } from '@blackjack/ui/components';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.4rem;
  height: 4.8rem;
  font-size: 1.6rem;
  span {
    color: var(--text-blue);
  }
`;

const TableName = () => {
  const { gameState } = useGameContext();
  const gameInfo = gameState.gameInfo;

  return (
    <CornerBox bottomRight>
      <Container>
        {gameInfo && (
          <p>
            {gameInfo.name}{' '}
            <span>
              ${gameInfo.min_bet} - ${gameInfo.max_bet}
            </span>
          </p>
        )}
      </Container>
    </CornerBox>
  );
};

export { TableName };
