import styled from '@emotion/styled';
import { Timer } from '../timer';
import { HitButtonRow } from '../buttons';
import { PlayerAction } from '@blackjack/models';
import { Modal } from './modal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;

  h2 {
    text-transform: uppercase;
    font-size: 2.6rem;
    color: #ffffff;
    margin-top: 2rem;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 0.4rem;
    margin-top: 1rem;
  }
`;

export interface PlayerActionModalProps {
  actions: PlayerAction[];
  seatId: number;
  endTime: number;
  handlePlayerAction: (
    action: PlayerAction,
    seatId: number,
    isPreAction?: boolean
  ) => void;
}

export const PlayerActionModal = (props: PlayerActionModalProps) => {
  const { actions, seatId, endTime, handlePlayerAction } = props;

  return (
    <Modal>
      <Container>
        <Timer endTime={endTime} />
        <h2>Make your decision</h2>
        <div className="button-wrapper">
          {actions && (
            <HitButtonRow
              seatId={seatId}
              handlePlayerAction={handlePlayerAction}
              availablePlayerAction={actions}
            />
          )}
        </div>
      </Container>
    </Modal>
  );
};
