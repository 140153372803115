import styled from '@emotion/styled';
import { Modal } from './modal';
import { Timer } from '../timer';
import { InsuranceButton } from '../buttons/insurance-button';
import { SvgButton } from '../buttons';
import { GameContextProps } from '@blackjack/ui/contexts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;

  h2 {
    text-transform: uppercase;
    font-size: 2.4rem;
    font-weight: bold;
    color: #ffffff;
    margin-top: 2rem;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 0.4rem;

    .button {
      height: 5rem;
    }
  }
`;

interface Props {
  endTime: number | null;
  handlePlayerInsurance: GameContextProps['handlePlayerInsurance'];
}

const Insurance = (props: Props) => {
  const { endTime, handlePlayerInsurance } = props;

  return (
    <Modal>
      <Container>
        <h2>Insurance?</h2>
        {endTime && <Timer endTime={endTime} />}
        <div className="button-wrapper">
          <SvgButton
            className="button"
            onClick={() => handlePlayerInsurance(true)}
          >
            <InsuranceButton isYes />
          </SvgButton>
          <SvgButton
            className="button"
            onClick={() => handlePlayerInsurance(false)}
          >
            <InsuranceButton isYes={false} />
          </SvgButton>
        </div>
      </Container>
    </Modal>
  );
};

export { Insurance };
