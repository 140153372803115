import styled from '@emotion/styled';
import { ReactNode, useMemo } from 'react';

const Container = styled.div<{ isRight: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isRight }) => (isRight ? 'flex-end' : 'flex-start')};
  margin: ${({ isRight }) => (isRight ? '3% 3% 3% 20%' : '3% 20% 3% 3%')};

  .chat-info {
    display: flex;

    color: #898989;
    text-transform: uppercase;
    font-size: 1.4rem;

    gap: 0.6rem;

    align-items: center;
    span {
      color: #898989;
    }
  }

  .chat-message {
    font-size: 1.6rem;
    font-weight: lighter;
    text-align: ${({ isRight }) => (isRight ? 'right' : 'left')};
  }

  &.inline {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    margin: 1.6rem 2rem;

    .chat-info {
      color: var(--text-blue);
      font-size: 1.6rem;
    }
    .chat-message {
      font-size: 1.6rem;
    }
  }
`;

interface Props {
  time: number;
  name: string;
  isRight?: boolean;
  inline?: boolean;
  children: ReactNode;
}

const ChatBubble = (props: Props) => {
  const { time, name, children, isRight = false, inline = false } = props;

  const formattedTime = useMemo(() => {
    const date = new Date(time);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
  }, [time]);

  return (
    <Container isRight={isRight} className={inline ? 'inline' : ''}>
      <span className="chat-info">
        {!inline && <span>{formattedTime}</span>} {name}
      </span>
      <span className="chat-message">{children}</span>
    </Container>
  );
};

export { ChatBubble, Props };
