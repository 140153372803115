import { v4 as uuidv4 } from 'uuid';

import { ICard } from '@shared/models';

export enum BaseEvents {
    User = 'User',
    UserJoin = 'UserJoin',
    UserDisconnect = 'UserDisconnect',
    InternalJoin = 'InternalJoin',
    ChatMessage = 'ChatMessage', // From Server
    SendChatMessage = 'SendChatMessage', // To Server
    Error = 'Error',
    DuplicateSession = 'DuplicateSession',

    Response = 'Response',

    CameraChange = 'CameraChange',

    // Specific to games
    CardUpdate = 'CardUpdate', // Single Card
    WaitingForCard = 'WaitingForCard',
    WheelUpdate = 'WheelUpdate',
    WaitingForWheel = 'WaitingForWheel',
}

export interface UserData {
    id: string;
    source_user_id: string;
    name: string;
    type: string;
    credits: number;
}

export type IBaseEvent<T, D = unknown> = [
    T,
    { event: T; message_id: string; payload: D; timestamp: number }
];

export const createEvent = <T, D = unknown>(
    type: T,
    data: D
): IBaseEvent<T, D> => {
    const uuid = uuidv4();
    return [
        type,
        { event: type, message_id: uuid, payload: data, timestamp: Date.now() },
    ];
};

export type BaseEvent<T = string, D = unknown> = {
    message_id: string;
    type: T;
    data: D;
};

export type ResponseMessage = {
    status: number;
    message: string;
};

export type ResponseEvent = IBaseEvent<
    'response',
    {
        status: number;
        message: string;
    }
>[1];

export const createResponseEvent = (
    messageId: string,
    response: ResponseMessage
) => {
    const ev = createEvent(BaseEvents.Response, response);
    ev[1].message_id = messageId;

    return ev;
};

export type _ResponseEvent = ReturnType<typeof createResponseEvent>[1];

export const createUserEvent = (user: UserData) => {
    return createEvent(BaseEvents.User, { user });
};

export type UserEvent = ReturnType<typeof createUserEvent>[1];

export const createSendChatMessageEvent = (message: string) => {
    return createEvent(BaseEvents.SendChatMessage, {
        message,
        timestamp: Date.now(),
    });
};

export type SendChatMessageEvent = ReturnType<
    typeof createSendChatMessageEvent
>[1];

export const createChatMessageEvent = (
    user_id: string,
    name: string,
    message: string,
    timestamp: number
) => {
    return createEvent(BaseEvents.ChatMessage, {
        user_id,
        timestamp,
        name,
        message,
    });
};

export type ChatMessageEvent = ReturnType<typeof createChatMessageEvent>[1];

export const createErrorEvent = (code: number, message: string) => {
    return createEvent(BaseEvents.Error, { code, message });
};

export type ErrorEvent = ReturnType<typeof createErrorEvent>[1];

export const createCardUpdateEvent = (card: ICard) => {
    return createEvent(BaseEvents.CardUpdate, { card });
};

export type CardUpdateEvent = ReturnType<typeof createCardUpdateEvent>[1];

export const createCameraChangeEvent = (camera: number) => {
    return createEvent(BaseEvents.CameraChange, { camera });
};

export type CameraChangeEvent = ReturnType<typeof createCameraChangeEvent>[1];

export const createWaitingForCardEvent = () => {
    return createEvent(BaseEvents.WaitingForCard, {});
};

export type WaitingForCard = ReturnType<typeof createWaitingForCardEvent>[1];

export const createWheelUpdateEvent = (wheel: number) => {
    return createEvent(BaseEvents.WheelUpdate, { wheel });
};

export type WheelUpdateEvent = ReturnType<typeof createWheelUpdateEvent>[1];

export const createWaitingForWheelEvent = () => {
    return createEvent(BaseEvents.WaitingForWheel, {});
};
