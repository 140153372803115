import styled from '@emotion/styled';
import { FormEventHandler, useState } from 'react';
import { useAuthentication } from '@shared/ui/hooks';
import { useWebsocketContext } from '@shared/ui/contexts';
import { CornerBox } from './corner-box';
import { keyframes } from '@emotion/react';

const anim = keyframes`
  from {
    top: -80vh;
    opacity: 0;
  } to {
    top: 0px;
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;

  justify-content: center;
  align-items: center;

  background-color: #000;

  font-size: 14px;

  font-family: 'Chakra Petch';

  background-size: 34px 34px;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);

  .login-form {
    animation: ${anim} 1s;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 400px;
    padding: 40px;
    border-radius: 6px;

    input {
      width: 100%;
      padding: 6px 20px;
      box-sizing: border-box;

      color: #fff;

      border-radius: 2px;

      background-color: var(--button-bg-color);
      border: 1px solid var(--border-color);
      outline: none;

      &:autofill {
        background-color: transparent;
        border: 1px solid var(--border-color);
      }

      &[type='submit'] {
        padding: 10px 20px;
        background-color: transparent;
        border: none;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .button {
      width: 120px;
      margin-left: auto;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s;
      -webkit-text-fill-color: #fff !important;
      font-family: 'Chakra Petch';
      font-size: 16px;
    }
  }
`;

export const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { updateAuthToken } = useWebsocketContext();
  const { authenticate } = useAuthentication();

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    const token = await authenticate(username, password);
    if (token) {
      updateAuthToken(token);
    }
  };

  return (
    <Container>
      <CornerBox
        className="login-form"
        topRight
        bottomLeft
        cornerSize={'40px'}
        borderRadius={'4px'}
        borderWidth={'1px'}
      >
        <form className="form" onSubmit={handleSubmit}>
          <input
            type="text"
            onChange={(e) => setUsername(e.currentTarget.value)}
            placeholder="Username"
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
          <CornerBox
            className="button"
            borderRadius={'4px'}
            cornerSize={'10px'}
            borderWidth={'1px'}
            bottomLeft
            bottomRight
          >
            <input type="submit" value="LOGIN" />
          </CornerBox>
        </form>
      </CornerBox>
    </Container>
  );
};
