import styled from '@emotion/styled';
import clsx from 'clsx';
import { ReactNode } from 'react';

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: solid;
  border-color: var(--border-color);
  border-width: var(--border-width);

  height: 3rem;
  width: 7rem;

  .text {
    font-size: 1.3rem;
    color: #93c2e8;

    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }

  &.left {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 1rem;
  }

  &.right {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 0.4rem;
  }

  &.isRed {
    .text {
      color: #ff5d5d;
    }
    border-color: #ff5d5d;
  }
`;

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  leftSide?: boolean;
  isRed?: boolean;
  children: ReactNode;
}

const MobileButton = (props: Props) => {
  const {
    onClick,
    disabled = false,
    isRed = false,
    leftSide = true,
    children,
  } = props;

  return (
    <Container
      className={clsx(leftSide ? 'left' : 'right', isRed ? 'isRed' : '')}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <span className="text">{children}</span>
    </Container>
  );
};

export { MobileButton, Props };
