import styled from '@emotion/styled';
import { ReactElement } from 'react';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: solid;
  border-color: var(--border-color);
  border-width: var(--border-width);

  box-sizing: border-box;

  height: 2.5rem;
  width: 3.3rem;

  cursor: pointer;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
    height: 40%;
  }

  &.left {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 0.4rem;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 1rem;
  }

  &.right {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 0.4rem;
  }
`;

interface Props {
  icon: ReactElement;
  leftSide?: boolean;
  onClick?: () => void;
}

const MobileSVGButton = (props: Props) => {
  const { icon, leftSide = true, onClick } = props;

  return (
    <Container
      className={leftSide ? 'left' : 'right'}
      onClick={onClick ? onClick : undefined}
    >
      {icon}
    </Container>
  );
};

export { MobileSVGButton, Props };
