import styled from '@emotion/styled';

export interface BigButtonProps {
    className?: string;
    text?: string;
    backgroundColor?: string;
    color?: string;
}

const Svg = styled.svg<Partial<BigButtonProps>>`
    path {
        fill: ${(props) => props.backgroundColor};
    }

    text {
        font-size: 56px;
        font-family: 'Chakra Petch';
        fill: ${(props) => props.color};
    }
`;

export const BigButton = (props: BigButtonProps) => {
    const {
        className = 'big-button',
        text = '+ HIT',
        backgroundColor = 'var(--button-green-bg-color)',
        color = 'var(--button-text-color)',
    } = props;
    return (
        <Svg
            viewBox="0 0 280 140"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            backgroundColor={backgroundColor}
            color={color}
        >
            <path d="M 28 4 L 253 4 L 277 28 L 277 126 L 267 136 L 14 136 L 3 126 L 3 28 L 28 4 Z" />
            <text
                x="50%"
                y="54%"
                textAnchor="middle"
                alignmentBaseline="middle"
            >
                {text}
            </text>
        </Svg>
    );
};
