import styled from '@emotion/styled';
import { CardHandMapper } from './card-hand-mapper';
import { ICard, IPlayerScore } from '@blackjack/models';
import { CornerBox } from '@blackjack/ui/components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.4rem;
  height: 15.4rem;
  flex-shrink: 1;
`;

const HandWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: 100%;
`;

const HandName = styled(CornerBox)`
  padding: 0px 1.2rem 0 1.2rem;
  height: 3rem;
  color: white;
  font-size: 1.4rem;
  align-items: center;
  margin-left: auto;
  text-transform: uppercase;

  font-family: 'Chakra Petch';
  text-align: right;

  .content {
    white-space: nowrap;
  }
`;

interface Props {
  hand: ICard[];
  score?: IPlayerScore;
  isDealer: boolean;
}

const Hand = (props: Props) => {
  const { hand, score, isDealer = true } = props;

  if (hand.length === 0) return null;
  return (
    <Container>
      <HandWrapper>
        <CardHandMapper hand={hand} score={score} />
      </HandWrapper>
      <HandName bottomLeft cornerSize={'1.0rem'} borderRadius={'0.4rem'}>
        {isDealer ? "Dealer's Hand" : 'Your Hand'}
      </HandName>
    </Container>
  );
};

export { Hand, Props };
