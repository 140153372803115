import { CornerBox } from '../layout';
import styled from '@emotion/styled';

const Container = styled.div`
  position: relative;
  .box {
    opacity: 1;
    transition: opacity 100ms ease-in-out;
  }
  .box2 {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
    position: absolute;
    top: 0;
  }
  &:hover {
    .box {
      opacity: 0;
    }
    .box2 {
      opacity: 1;
    }
  }
`;

/**
 * Dodgy hack to get the background of the button to transition
 */
export const DealCardsButton = (props: { onClick?: () => void }) => {
  const { onClick } = props;
  return (
    <Container className="clicky" onClick={onClick}>
      <CornerBox
        className="box"
        borderRadius={'0.4rem'}
        cornerSize={'0.8rem'}
        bottomLeft
        topRight
        topLeft
        bottomRight
        backgroundColor={'var(--button-default-bg-color)'}
      >
        DEAL CARDS
      </CornerBox>
      <CornerBox
        className="box2"
        borderRadius={'0.4rem'}
        cornerSize={'0.8rem'}
        bottomLeft
        topRight
        topLeft
        bottomRight
        backgroundColor={'var(--button-green-bg-color)'}
      >
        DEAL CARDS
      </CornerBox>
    </Container>
  );
};
