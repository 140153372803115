import { ReactComponent as Fullscreen } from '../assets/icons/Fullscreen_icon.svg';
import { ReactComponent as Minimize } from '../assets/icons/Minimize_icon.svg';
import { ReactComponent as Setting } from '../assets/icons/Settings_icon.svg';
import { ReactComponent as Show } from '../assets/icons/Show_icon.svg';
import { ReactComponent as Hide } from '../assets/icons/Hide_icon.svg';
import { ReactComponent as Mute } from '../assets/icons/Mute_icon.svg';
import { ReactComponent as Unmute } from '../assets/icons/Unmute_icon.svg';

export const ICONS = {
  Fullscreen: <Fullscreen />,
  Minimize: <Minimize />,
  Setting: <Setting />,
  Show: <Show />,
  Hide: <Hide />,
  Mute: <Mute />,
  Unmute: <Unmute />,
};
