import { CornerBox } from '@blackjack/ui/components';
import { useChatContext } from '@shared/ui/contexts';
import { useGameContext } from '@blackjack/ui/contexts';
import styled from '@emotion/styled';
import { KeyboardEventHandler, useState } from 'react';
import { ChatBubble } from './chat-bubble';
import { keyframes } from '@emotion/react';

const EXPANDED_HEIGHT = '40rem';

const ExpandingBox = styled(CornerBox)<{ show: boolean }>`
    height: 4.8rem;
    pointer-events: auto;

    transition: height 500ms ease-in-out;

    .new-message-icon {
        visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background: var(--text-blue);
    }

    &.expanded {
        height: ${EXPANDED_HEIGHT};
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;
        height: 4.8rem;
        transition: height 500ms ease-in-out;
    }

    &.expanded {
        .content {
            height: ${EXPANDED_HEIGHT};
        }
    }

    .chat-text {
        display: flex;
        flex: 1;
        flex-direction: column-reverse;
        pointer-events: auto;
        border-bottom: var(--border-width) solid transparent;
        transition: border-color 500ms ease-in-out, opacity 500ms ease-in-out;
    }

    &.expanded {
        .chat-text {
            border-bottom-color: var(--border-color);
            opacity: 1;
        }
    }

    .chat-text-input {
        width: 90%;
        background: none;
        border: unset;
        padding: 1.4rem 1.4rem;
        font-family: 'Chakra Petch', sans-serif;
        caret-color: #ffffff;
        color: #ffffff;
        font-size: 1.6rem;

        outline: none;

        ::placeholder {
            border: unset;
            font-size: 1.6rem;
            color: #ddd;
        }
    }
`;

const messageIn = keyframes`
  0%, 100% {
    opacity: 0;
    transform: translate(0, -2rem);
  }
  10%,90% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const Wrapper = styled.div`
    position: relative;
    .last-message {
        animation: ${messageIn} 5s forwards;
        position: absolute;
        width: 100%;
    }
`;

const Chat = () => {
    const { messages, sendChatMessage, hasNewMessages, setHasNewMessages } =
        useChatContext();
    const { gameState } = useGameContext();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter' && event.currentTarget.value !== '') {
            sendChatMessage(event.currentTarget.value);

            event.currentTarget.value = '';
        }
    };

    return (
        <Wrapper>
            <ExpandingBox
                topRight
                className={isExpanded ? 'expanded' : ''}
                show={hasNewMessages && !isExpanded}
            >
                <div className="new-message-icon" />
                <div className="content">
                    <div className="chat-text">
                        {messages.map((msg) => {
                            const isCurrentUser =
                                msg.payload.user_id === gameState.user?.id;
                            return (
                                <ChatBubble
                                    key={msg.message_id}
                                    time={msg.payload.timestamp}
                                    name={msg.payload.name}
                                    isRight={isCurrentUser}
                                >
                                    {msg.payload.message}
                                </ChatBubble>
                            );
                        })}
                    </div>
                    <input
                        className="chat-text-input"
                        onClick={() => {
                            setIsExpanded(true);
                            setHasNewMessages(false);
                        }}
                        onKeyDown={handleKeyDown}
                        onBlur={() => {
                            setIsExpanded(false);
                            setHasNewMessages(false);
                        }}
                        placeholder="Type here to chat... "
                    />
                </div>
            </ExpandingBox>
            {hasNewMessages && !isExpanded && (
                <div className="last-message" key={messages[0].message_id}>
                    <ChatBubble
                        time={messages[0].payload.timestamp}
                        name={messages[0].payload.name}
                        inline
                    >
                        {messages[0].payload.message}
                    </ChatBubble>
                </div>
            )}
        </Wrapper>
    );
};

export { Chat };
