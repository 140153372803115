import styled from '@emotion/styled';
import { ReactElement, ReactNode } from 'react';

export interface CornerBoxProps {
  className?: string;
  topLeft?: boolean;
  topRight?: boolean;
  bottomRight?: boolean;
  bottomLeft?: boolean;

  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: string;
  borderRadius?: string;

  cornerSize?: string;

  children?:
    | string
    | ReactElement
    | ReactElement[]
    | ReactNode
    | ReactNode[]
    | null;
}

const Wrapper = styled.div<{ cornerSize: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  min-height: calc(${(props) => `${props.cornerSize} + ${props.cornerSize}`});
`;

const generateCorner = (angle: number, props: CornerBoxProps) => {
  return `background: linear-gradient(
        ${angle}deg,
        ${props.backgroundColor}
          calc(50% - ${props.borderWidth}),
        ${props.borderColor} calc(50%),
        transparent calc(50% + ${props.borderWidth})
      )`;
};

const Container = styled.div<CornerBoxProps>`
  display: grid;
  grid-template-columns: ${(props) => props.cornerSize} auto ${(props) =>
      props.cornerSize};
  grid-template-rows: ${(props) => props.cornerSize} auto ${(props) =>
      props.cornerSize};
  box-sizing: border-box;

  position: absolute;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  z-index: 0;

  div {
    background-color: ${(props) => props.backgroundColor};
  }

  .top {
    border-top: ${(props) => `${props.borderWidth} solid ${props.borderColor}`};
  }
  .left {
    border-left: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
  }
  .bottom {
    border-bottom: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
  }
  .right {
    border-right: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
  }

  .top-left {
    border-top: ${(props) => `${props.borderWidth} solid ${props.borderColor}`};
    border-left: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
    border-top-left-radius: ${(props) => props.borderRadius};
    &.corner {
      ${(props) => generateCorner(-45, props)};
    }
  }
  .top-right {
    border-top: ${(props) => `${props.borderWidth} solid ${props.borderColor}`};
    border-right: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
    border-top-right-radius: ${(props) => props.borderRadius};

    &.corner {
      ${(props) => generateCorner(45, props)};
    }
  }

  .bottom-left {
    border-bottom: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
    border-left: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
    border-bottom-left-radius: ${(props) => props.borderRadius};

    &.corner {
      ${(props) => generateCorner(-135, props)};
    }
  }

  .bottom-right {
    border-bottom: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
    border-right: ${(props) =>
      `${props.borderWidth} solid ${props.borderColor}`};
    border-bottom-right-radius: ${(props) => props.borderRadius};

    &.corner {
      ${(props) => generateCorner(135, props)};
    }
  }

  .corner {
    border: none;
    border-radius: none;
  }
`;

const Content = styled.div`
  z-index: 1;
  position: relative;
  flex: 1;
`;

export const CornerBox = (props: CornerBoxProps) => {
  const {
    className = '',
    topLeft = false,
    topRight = false,
    bottomLeft = false,
    bottomRight = false,
    backgroundColor = 'var(--button-bg-color)',
    borderColor = 'var(--border-color)',
    borderWidth = 'var(--border-width)',
    borderRadius = '0.8rem',
    cornerSize = '2rem',
    children,
  } = props;
  return (
    <Wrapper cornerSize={cornerSize} className={className}>
      <Container
        className="container"
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderWidth={borderWidth}
        borderRadius={borderRadius}
        cornerSize={cornerSize}
      >
        <div className={`top-left ${topLeft ? 'corner' : ''}`}></div>
        <div className="top"></div>
        <div className={`top-right ${topRight ? 'corner' : ''}`}></div>
        <div className="left"></div>
        <div className="center"></div>
        <div className="right"></div>
        <div className={`bottom-left ${bottomLeft ? 'corner' : ''}`}></div>
        <div className="bottom"></div>
        <div className={`bottom-right ${bottomRight ? 'corner' : ''}`}></div>
      </Container>
      <Content className="content">{children}</Content>
    </Wrapper>
  );
};
