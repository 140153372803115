import { VideoStreamMillicast } from './video-stream.millicast';
import { VideoStreamPixelStream } from './video-stream.pixelstream';

export interface VideoStreamProps {
  type: 'pixelstream' | 'webrtc' | 'hls' | 'millicast';
  url: string;
  authkey?: string;
  muted?: boolean;
  onReady: () => void;
}

export const VideoStream = (props: VideoStreamProps) => {
  const { type, url, muted, authkey, onReady } = props;

  if (type === 'pixelstream') {
    return <VideoStreamPixelStream onReady={onReady} muted={muted} />;
  }

  if (type === 'millicast' && authkey) {
    return (
      <VideoStreamMillicast
        accountId={authkey}
        name={url}
        muted={muted}
        onReady={onReady}
      />
    );
  }
  return null;
};
