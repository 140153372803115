import { z } from 'zod';
import { selectServiceSchema } from './services.schema';

const partialService = selectServiceSchema.pick({
    id: true,
    type: true,
    internalId: true,
    linkedId: true,
});

export const serviceToken = partialService.merge(
    z.object({
        registered: z.boolean(),
        source: z.string(),
        host: z.string().url().optional(),
    })
);

export type ServiceToken = z.infer<typeof serviceToken>;
