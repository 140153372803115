import styled from '@emotion/styled';
import { ReactComponent as InsuranceYes } from '../../assets/icons/Insurance_yes.svg';
import { ReactComponent as InsuranceNo } from '../../assets/icons/Insurance_no.svg';

export interface InsuranceButtonProps {
  isYes: boolean;
}

const Svg = styled.svg<{ isYes: boolean }>`
  .background {
    fill: ${({ isYes }) =>
      isYes ? 'var(--button-green-bg-color)' : 'var(--button-red-bg-color)'};
  }

  text {
    font-size: 56px;
    font-family: 'Chakra Petch';
    fill: #ffffff;
  }
`;

/**
 * Button to handle insurance
 * Uses multiple wrapped SVGs to handle positioning and also to avoid a TS error by placing x and y positioning on an imported SVG
 * @param props
 * @constructor
 */
export const InsuranceButton = (props: InsuranceButtonProps) => {
  const { isYes = true } = props;
  return (
    <Svg viewBox="0 0 280 140" xmlns="http://www.w3.org/2000/svg" isYes={isYes}>
      <path
        className="background"
        d="M 28 4 L 253 4 L 277 28 L 277 126 L 267 136 L 14 136 L 3 126 L 3 28 L 28 4 Z"
      />
      <g alignmentBaseline="middle">
        <svg x="-20%" y="20%" height="60%">
          {isYes ? <InsuranceYes /> : <InsuranceNo />}
        </svg>
        <text x="70%" y="54%" textAnchor="middle" alignmentBaseline="middle">
          {isYes ? 'Yes' : 'No'}
        </text>
      </g>
    </Svg>
  );
};
