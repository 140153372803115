import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { CornerBox } from '@blackjack/ui/components';

const Container = styled.div<{ leftSide: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ leftSide }) => (leftSide ? 'flex-start' : 'flex-end')};
  min-width: 10rem;
  padding: 1.2rem 2rem;

  .button-title {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: var(--text-blue);
  }

  .button-value {
    font-size: 2rem;
  }
`;

interface Props {
  leftSide?: boolean;
  title: string;
  children: ReactNode;
}

const BalanceBetButtons = (props: Props) => {
  const { leftSide = false, title, children } = props;

  return (
    <CornerBox topRight={leftSide} topLeft={!leftSide} cornerSize="2.4rem">
      <Container leftSide={leftSide}>
        <span className="button-title">{title}</span>
        <span className="button-value">{children}</span>
      </Container>
    </CornerBox>
  );
};

export { BalanceBetButtons, Props };
