import styled from '@emotion/styled';
import { CornerBox } from './corner-box';
import { BigButton, SvgButton } from './buttons';

const Container = styled.div`
    position: fixed;
    z-index: 10001;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 192rem;
    height: 108rem;

    margin-left: calc(-192rem / 2);
    left: 50%;
    top: 0;

    pointer-events: auto;

    &.hide {
        display: none;
    }

    .form {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 4rem 4rem;

        pointer-events: auto;

        .button {
            height: 5rem;
        }
    }

    .message {
        font-size: 1.5rem;
    }
`;

const Wrapper = styled.div`
    width: 45rem;
`;

export interface DuplicatePopUpProps {
    isVisible: boolean;
    onClose: () => void;
}

export const DuplicatePopUp = (props: DuplicatePopUpProps) => {
    const { isVisible, onClose } = props;
    return (
        <Container onClick={onClose} className={isVisible ? 'show' : 'hide'}>
            <Wrapper>
                <CornerBox
                    topRight
                    bottomLeft
                    topLeft
                    bottomRight
                    cornerSize={'2rem'}
                >
                    <div className="form">
                        <span className="message">
                            We've detected another active session associated
                            with your account. To ensure the security of your
                            account and data, we're disconnecting this session.
                        </span>
                        <SvgButton onClick={onClose} className="button close">
                            <BigButton
                                text="Close"
                                backgroundColor="var(--button-red-bg-color)"
                            />
                        </SvgButton>
                    </div>
                </CornerBox>
            </Wrapper>
        </Container>
    );
};
