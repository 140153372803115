import { useMemo, useCallback } from 'react';
import styled from '@emotion/styled';

import { useGameContext } from '@blackjack/ui/contexts';
import { HitButtonRow } from '@blackjack/ui/components';

import { CardHand } from './mobile-card-hand';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

  color: #ffffff;
  font-family: 'Chakra Petch', sans-serif;
  border: 1px solid red;
  box-sizing: border-box;

  .cards {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;

    border: 1px solid red;
    box-sizing: border-box;

    .dealer-cards {
      width: 50%;
      height: 100%;
      border: 1px solid green;
      box-sizing: border-box;
    }

    .player-cards {
      width: 50%;
      height: 100%;
      border: 1px solid green;
      box-sizing: border-box;
    }
  }

  .player-action {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;

    border: 1px solid red;
    box-sizing: border-box;

    .decision-heading {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-items: center;
    }

    .action-row {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      justify-items: center;

      border: 1px solid blue;
      box-sizing: border-box;
    }
  }
`;

const HandWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: 100%;
`;

export const PlayerAction = () => {
  const { gameState, handlePlayerAction, isOwnSeat } = useGameContext();

  const isPlayerSeat = gameState.userSeatIds.includes(
    gameState.currentState.seat_id
  );

  const endTime =
    (gameState.currentAction?.start_time || 0) +
    (gameState.currentAction?.duration || 0);

  const activeSeat = gameState.currentAction?.seat;

  const isPlayersHand = useCallback(() => {
    if (gameState.currentAction && activeSeat !== undefined) {
      return isOwnSeat(activeSeat);
    }
    return false;
  }, [activeSeat, gameState.currentAction, isOwnSeat]);

  const player = useMemo(() => {
    return gameState.players.find((p) => p.seat === activeSeat);
  }, [gameState.players, activeSeat]);

  const currentHand = gameState.currentState.hand;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const playerCards = useMemo(() => {
    if (isPlayersHand()) {
      const player = gameState.players.find((p) => p.seat === activeSeat);
      return player?.cards[currentHand] || [];
    }
    return [];
  }, [gameState.players, currentHand, isPlayersHand, activeSeat]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dealerCards = gameState?.dealer?.cards[0] ?? [];

  return (
    <Container>
      <div className="cards">
        <div className="dealer-cards">
          <HandWrapper>
            <CardHand
              hand={[
                {
                  id: 1234,
                  uuid: '12345',
                  face: 'diamond',
                  value: 10,
                },
                {
                  id: 1234,
                  uuid: '23456',
                  face: 'diamond',
                  value: 10,
                },
              ]}
              score={gameState.dealer?.scores[0]}
            />
          </HandWrapper>
        </div>
        <div className="player-cards">
          <HandWrapper>
            <CardHand
              // hand={playerCards}
              hand={[
                {
                  id: 1234,
                  uuid: '345678',
                  face: 'diamond',
                  value: 3,
                },
                {
                  id: 1234,
                  uuid: '456789',
                  face: 'diamond',
                  value: 7,
                },
                {
                  id: 1234,
                  uuid: '567891',
                  face: 'diamond',
                  value: 4,
                },
                {
                  id: 1234,
                  uuid: '678912',
                  face: 'diamond',
                  value: 6,
                },
              ]}
              score={player?.scores[currentHand]}
            />
          </HandWrapper>
        </div>
      </div>
      <div className="player-action">
        <span className="decision-heading">MAKE YOUR DECISION</span>
        <div className="action-row">
          {isPlayerSeat && endTime && gameState.currentAction && (
            <HitButtonRow
              seatId={gameState.currentState.seat_id}
              handlePlayerAction={handlePlayerAction}
              availablePlayerAction={gameState.currentAction.actions}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
