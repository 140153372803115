import { GameInfo } from '@blackjack/ui/components';
import { FullscreenLoader } from '@shared/ui/components';
import { VirtualTable } from '@blackjack/ui/components-3d';
import styled from '@emotion/styled';
import { Toaster } from 'react-hot-toast';
import { LeftColumn } from '../components/left-column';
import { RightColumn } from '../components/right-column';

import ambienceSound from '../../assets/audio/ambience-2.mp3';

import { VideoStream } from '@shared/ui/components/generic';
import { useProgress } from '@react-three/drei';
import { useTableStateHook } from '../hooks/use-table-state.hook';

import {
    useGameContext,
    useLogicStateContext,
    useLogicActionContext,
} from '@blackjack/ui/contexts';
import { DuplicatePopUp } from '@shared/ui/components';
import { CustomPopUp } from '../components/custom-pop-up';
import { useGameMessageHook } from '@blackjack/ui/hooks';
import { useWebsocketContext } from '@shared/ui/contexts';
import { useAuthentication } from '@shared/ui/hooks';
import { useState } from 'react';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .video {
        width: 100%;
        height: 100%;
        position: absolute;
    }
`;

const ColumnSide = styled.div`
    display: flex;
    max-width: 20%;
    flex: 1;
    flex-direction: column;
    z-index: 10;

    pointer-events: none;

    .svg-wrapper {
        height: 10vh;
        width: 10vh;
    }
`;
const ColumnCenter = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    pointer-events: none;
    z-index: 10;
`;

const GameHeader = styled.div`
    display: flex;
    flex: 0.5;
`;

const GameInfoContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    pointer-events: none;
`;

const GameStateContainer = styled.span`
    position: absolute;
    color: white;
    bottom: 0;
    right: 2rem;
`;

const DesktopAppLayout = () => {
    const {
        gameState,
        handlePlayerAction: handleRoundAction,
        muted,
        previousWin,
    } = useGameContext();

    const {
        totalCredits,
        userTotalSeats,
        isReady,
        audioRef,
        betHistory,
        baseBetTotal,
        sideBetTotals,
    } = useLogicStateContext();

    const { handleVideoReady, handlePlayerClick, handlePlayerAction } =
        useLogicActionContext();

    const { active, progress } = useProgress();

    const tableState = useTableStateHook();

    const gameMessage = useGameMessageHook(gameState, 7, isReady);

    const { duplicateSession } = useWebsocketContext();
    const { clearAuthentication } = useAuthentication();

    const [finishedLoading, setFinishedLoading] = useState(false);

    const handleLogout = () => {
        clearAuthentication();
        window.location.reload();
    };

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <Container>
                <div className="video">
                    {gameState.gameInfo?.streamData && (
                        <VideoStream
                            authkey={gameState.gameInfo?.streamData?.authkey}
                            url={gameState.gameInfo?.streamData.host}
                            type={gameState.gameInfo.streamData.type}
                            muted={muted}
                            onReady={handleVideoReady}
                        />
                    )}
                </div>
                <VirtualTable
                    state={tableState}
                    players={gameState.players}
                    userId={gameState.user?.id || ''}
                    userBets={betHistory}
                    currentTurn={gameState?.currentState?.seat_id ?? -1}
                    muted={muted}
                    onPlayerAction={handlePlayerAction}
                    onPlayerClick={handlePlayerClick}
                    onPlayerPreAction={handleRoundAction}
                    cameraConfig={{
                        orbitControls: false,
                        position: {
                            x: -0.0,
                            y: 2.730863487908139,
                            z: 6.951068591837024,
                        },
                        grid: false,
                        lookAt: {
                            x: -0.0,
                            y: -0.0,
                            z: 0.0,
                        },
                        focalLength: 16,
                    }}
                    config={{
                        totalSeats: 7,
                        startAngle: -50,
                        endAngle: 50,
                        radius: 3.6,
                        center: {
                            x: -0.0,
                            y: 0.6,
                        },
                    }}
                />
                <ColumnSide>
                    <LeftColumn
                        previousWin={previousWin}
                        betAmount={
                            baseBetTotal * userTotalSeats + sideBetTotals
                        }
                        credits={totalCredits}
                    />
                </ColumnSide>
                <ColumnCenter>
                    <GameHeader />
                    <GameInfoContainer>
                        {!duplicateSession && <GameInfo />}
                    </GameInfoContainer>
                </ColumnCenter>
                <ColumnSide className="right">
                    <RightColumn />
                </ColumnSide>
                {process.env.NODE_ENV === 'development' && (
                    <GameStateContainer>
                        {gameState.currentState.next_state}
                    </GameStateContainer>
                )}
            </Container>
            <DuplicatePopUp
                isVisible={duplicateSession}
                onClose={handleLogout}
            />
            <CustomPopUp
                isVisible={
                    gameMessage.show && !duplicateSession && finishedLoading
                }
                onClose={() => null}
                message={gameMessage.message}
            />
            <FullscreenLoader
                progress={((isReady ? 1.0 : 0) + progress / 100.0) / 2}
                minTime={0}
                isLoading={!isReady || active}
                onFinish={() => setFinishedLoading(true)}
            />
            <audio autoPlay muted={muted} ref={audioRef} loop>
                <source src={ambienceSound} type="audio/mpeg" />
            </audio>
        </>
    );
};

export { DesktopAppLayout };
