/* eslint-disable @typescript-eslint/no-explicit-any */
const LOG_CATEGORIES = {
  config: '📋',
  error: '💥',
  info: 'ℹ️',
  input: '🔠',
  success: '✅',
  warn: '❗️',
  socket: '🔌',
};

type LogCategory = keyof typeof LOG_CATEGORIES;

type LogFunction = {
  [key in LogCategory]: (message: string, data?: unknown) => void;
};

export const isObject = (value: unknown) => {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

const writeLog = (category: LogCategory, message: string, data?: any) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    /**
     * If in development mode stringify any object data and console.log
     */
    const formattedData = (value: any) => {
      if (isObject(value)) {
        return `\n${JSON.stringify(value, null, 2)}`;
      }

      return value;
    };

    const logEmoji =
      LOG_CATEGORIES[category] || LOG_CATEGORIES['info' as LogCategory];

    const params = [`${logEmoji} ${message}`];

    if (data) {
      params.push(formattedData(data));
    }

    console.log(...params);
  } else {
    /**
     * If in production add Sentry breadcrumb and an exception if it
     * is an 'error' category
     */
    // Sentry.addBreadcrumb({category, message, level, data});
  }
};

/**
 * Performs logging to Sentry breadcrumbs and to the console in
 * development mode.
 */
export const log: LogFunction = {
  config: (message, data) => writeLog('config', message, data),
  error: (message, data) => writeLog('error', message, data),
  info: (message, data) => writeLog('info', message, data),
  input: (message, data) => writeLog('input', message, data),
  success: (message, data) => writeLog('success', message, data),
  warn: (message, data) => writeLog('warn', message, data),
  socket: (message, data) => writeLog('socket', message, data),
};
