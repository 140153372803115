import { v4 as uuidv4 } from 'uuid';
import { BaseEvent, createEvent } from '@shared/events';
import {
  PlayerAction,
  PlayerBet,
  PlayerData,
  PlayerPayout,
} from './player.type';
import { IMarker, IPlayerCard } from './card.type';

import { ICard } from '@shared/models';

export * from '@shared/events';

export enum BlackJackStates {
  Unknown = 'Unknown',
  WaitingForPlayers = 'WaitingForPlayers',
  WaitingForPlayerAction = 'WaitingForPlayerAction',
  WaitingForBets = 'WaitingForBets',
  WaitingForCards = 'WaitingForCards',
  WaitingForHitCard = 'WaitingForHitCard',
  WaitingForSplitCards = 'WaitingForSplitCards',
  WaitingForDealerCard = 'WaitingForDealerCard',
  WaitingForInsurance = 'WaitingForInsurance',
  FinishedBetting = 'FinishedBetting',
  PlayerEndTurn = 'PlayerEndTurn',
  GameEnd = 'GameEnd',
}

export enum Events {
  CardsUpdate = 'CardsUpdate', // All cards
  GameInfo = 'GameInfo',
  GameState = 'GameState',
  Payout = 'Payout',
  PlayerAction = 'PlayerAction',
  PlayerBet = 'PlayerBet',
  PlayerBets = 'PlayerBets',
  PlayerLeaveSeat = 'PlayerLeaveSeat',
  PlayerSit = 'PlayerSit',
  PlayerTurn = 'PlayerTurn',
  PlayerDealNow = 'PlayerDealNow',
  Players = 'Players',
  RoundEnd = 'RoundEnd',
  RoundStart = 'RoundStart',
  RoundActions = 'RoundActions',
  OfferInsurance = 'OfferInsurance',
  PlayerInsurance = 'PlayerInsurance',
  UserJoin = 'UserJoin',
}

export type CardsEvent = BaseEvent<'cards', { cards: ICard[] }>;

export type PlayerCardsEvent = BaseEvent<
  'player_cards',
  { cards: IPlayerCard[] }
>;

// @deprecated
export type CardMarkersEvent = BaseEvent<
  'card_markers',
  {
    markers: IMarker[];
  }
>;

export const _createEvent = <D = unknown, T = 'unknown'>(
  type: T,
  data: D
): BaseEvent<T, D> => {
  const uuid = uuidv4();
  return {
    message_id: uuid,
    type,
    data,
  };
};

export const createCardMarkersEvent = (
  markers: IMarker[]
): CardMarkersEvent => {
  return _createEvent('card_markers', { markers });
};

export const createGameStateEvent = (
  previousState: BlackJackStates,
  nextState: BlackJackStates,
  seatId: number,
  hand: number
) => {
  return createEvent(Events.GameState, {
    previous_state: previousState,
    next_state: nextState,
    seat_id: seatId,
    hand,
  });
};

export type GameStateEvent = ReturnType<typeof createGameStateEvent>[1];

// RoundStartEvent
export const createRoundStartEvent = (
  roundId: string,
  bet_amounts: number[],
  players: PlayerData[],
  start_time: number | null,
  duration: number | null
) => {
  return createEvent(Events.RoundStart, {
    round_id: roundId,
    players,
    bet_amounts,
    start_time,
    duration,
  });
};

export type RoundStartEvent = ReturnType<typeof createRoundStartEvent>[1];

// RoundEndEvent
export const createRoundEndEvent = (
  roundId: string,
  totalWon: number,
  payouts: PlayerPayout[]
) => {
  return createEvent(Events.RoundEnd, {
    round_id: roundId,
    total_won: totalWon,
    payouts,
  });
};

export type RoundEndEvent = ReturnType<typeof createRoundEndEvent>[1];

export const createPayoutEvent = (roundId: string, payouts: PlayerPayout[]) => {
  return createEvent(Events.Payout, {
    round_id: roundId,
    payouts,
  });
};

export type PayoutEvent = ReturnType<typeof createPayoutEvent>[1];
export type PayoutData = PayoutEvent['payload'];

// PlayerSitEvent
export const createPlayerSitEvent = (seat: number) => {
  return createEvent(Events.PlayerSit, { seat });
};

export type PlayerSitEvent = ReturnType<typeof createPlayerSitEvent>[1];

export const createPlayerLeaveSeatEvent = (seat: number) => {
  return createEvent(Events.PlayerLeaveSeat, { seat });
};

export type PlayerLeaveSeatEvent = ReturnType<
  typeof createPlayerLeaveSeatEvent
>[1];

// PlayerActionEvent
export const createPlayerActionEvent = (action: PlayerAction, seat: number) => {
  return createEvent(Events.PlayerAction, {
    action,
    seat,
  });
};

export type PlayerActionEvent = ReturnType<typeof createPlayerActionEvent>[1];

export const createInsuranceOfferEvent = (
  startTime: number,
  duration: number
) => {
  return createEvent(Events.OfferInsurance, { startTime, duration });
};

export type InsuranceOfferData = InsuranceOfferEvent['payload'];
export type InsuranceOfferEvent = ReturnType<
  typeof createInsuranceOfferEvent
>[1];

export const createPlayerInsuranceEvent = (insurance: boolean) => {
  return createEvent(Events.PlayerInsurance, { insurance });
};

export type PlayerInsuranceEvent = ReturnType<
  typeof createPlayerInsuranceEvent
>[1];

export type PlayerTurnData = {
  seat: number;
  hand: number;
  start_time: number;
  duration: number;
  actions: PlayerAction[];
};
// PlayerTurnEvent
export const createPlayerTurnEvent = (
  seat: number,
  hand: number,
  start_time: number,
  duration: number,
  actions: PlayerAction[]
) => {
  return createEvent(Events.PlayerTurn, {
    seat,
    hand,
    start_time,
    duration,
    actions,
  });
};

export type PlayerTurnEvent = ReturnType<typeof createPlayerTurnEvent>[1];

// PlayerActionEvent
export const createPlayerBetEvent = (
  amount: number,
  seat: number,
  type: PlayerBet
) => {
  return createEvent(Events.PlayerBet, {
    amount,
    seat,
    type,
  });
};

export type PlayerBetEvent = ReturnType<typeof createPlayerBetEvent>[1];

export interface IPlayerBet {
  amount: number;
  seat: number;
  type: PlayerBet;
}

export const createPlayerBetsEvent = (bets: IPlayerBet[]) => {
  return createEvent(Events.PlayerBets, { bets });
};

export type PlayerBetsEvent = ReturnType<typeof createPlayerBetsEvent>[1];

export const createPlayersEvent = (
  players: PlayerData[],
  dealer: PlayerData
) => {
  return createEvent(Events.Players, {
    players: players.sort((a, b) => a.seat - b.seat),
    dealer,
  });
};

export type PlayersEvent = ReturnType<typeof createPlayersEvent>[1];

export const createCardsUpdateEvent = (cards: IPlayerCard[]) => {
  return createEvent(Events.CardsUpdate, { cards });
};

export type CardsUpdateEvent = ReturnType<typeof createCardsUpdateEvent>[1];

export type VideoStreamData = {
  type: 'webrtc' | 'pixelstream' | 'hls';
  host: string;
  authkey?: string;
};

export const createGameInfoEvent = (
  name: string,
  minBet: number,
  maxBet: number,
  minSideBet: number,
  maxSideBet: number,
  betAmounts: number[],
  streamData?: VideoStreamData
) => {
  return createEvent(Events.GameInfo, {
    min_bet: minBet,
    max_bet: maxBet,
    min_side_bet: minSideBet,
    max_side_bet: maxSideBet,
    bet_amounts: betAmounts,
    name,
    streamData,
  });
};
export type GameInfoEvent = ReturnType<typeof createGameInfoEvent>[1];
export type GameInfoData = GameInfoEvent['payload'];

export type RoundAction = {
  seat_id: number;
  actions: PlayerAction[];
};

export const createRoundActionsEvent = (
  roundId: string,
  actions: RoundAction[]
) => {
  return createEvent(Events.RoundActions, { round_id: roundId, actions });
};

export type RoundActionEvent = ReturnType<typeof createRoundActionsEvent>[1];

export const createPlayerDealNowEvent = () =>
  createEvent(Events.PlayerDealNow, {});

export type PlayerDealNowEvent = ReturnType<typeof createPlayerDealNowEvent>[1];

export interface ServerToClientEvents {
  [Events.Players]: (event: PlayersEvent) => void;
  [Events.GameState]: (event: GameStateEvent) => void;
  [Events.RoundStart]: (event: RoundStartEvent) => void;
  [Events.RoundEnd]: (event: RoundEndEvent) => void;
  [Events.Payout]: (event: PayoutEvent) => void;
  [Events.PlayerTurn]: (event: PlayerTurnEvent) => void;
}

export interface ClientToServerEvents {
  [Events.PlayerBet]: (event: PlayerBetEvent) => void;
  [Events.PlayerAction]: (event: PlayerActionEvent) => void;
  [Events.PlayerSit]: (event: PlayerSitEvent) => void;
}
