import styled from '@emotion/styled';
import { ReactElement, ReactNode } from 'react';
import { CornerBox } from '../layout';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  height: 3.4rem;
  width: 6rem;

  cursor: pointer;

  svg {
    fill: #ffffff;
    stroke: #ffffff;
    height: 60%;
  }

  &.disabled {
    cursor: default;
    svg {
      fill: rgba(255, 255, 255, 0.74);
      stroke: rgba(255, 255, 255, 0.4);
    }
  }
`;

interface Props {
  icon: ReactElement;
  disabled?: boolean;
  onClick?: () => void;
}

const SettingButton = (props: Props) => {
  const { icon = null, disabled = false, onClick } = props;

  return (
    <CornerBox
      topRight
      bottomLeft
      cornerSize={'1.4rem'}
      backgroundColor={
        disabled ? 'var(--button-disabled-bg-color)' : 'var(--button-bg-color)'
      }
      borderColor={
        disabled ? 'var(--border-disabled-color)' : 'var(--border-color)'
      }
    >
      <Container
        className={disabled ? 'disabled' : ''}
        onClick={disabled ? undefined : onClick}
      >
        {icon && (icon as unknown as ReactNode)}
      </Container>
    </CornerBox>
  );
};

export { SettingButton, Props };
