import { useGameContext } from '@blackjack/ui/contexts';
import { BlackJackStates } from '@blackjack/models';
import { useEffect, useState } from 'react';

export const useTableStateHook = () => {
  const { gameState } = useGameContext();

  const [clearBets, setClearBets] = useState(false);

  useEffect(() => {
    if (gameState.currentState.next_state === BlackJackStates.GameEnd) {
      setTimeout(() => {
        setClearBets(true);
      }, 3000);
    }

    if (gameState.currentState.next_state === BlackJackStates.WaitingForBets) {
      setClearBets(false);
    }
  }, [gameState.currentState.next_state]);

  const { next_state } = gameState.currentState;
  if (next_state === BlackJackStates.WaitingForBets) {
    return 'EDIT';
  }

  if (next_state === BlackJackStates.GameEnd) {
    if (clearBets) return 'CLEAR';
    return 'BASE';
  }

  if (next_state === BlackJackStates.WaitingForPlayerAction) return 'SIDEBETS';
  if (next_state === BlackJackStates.WaitingForHitCard) return 'SIDEBETS';
  if (next_state === BlackJackStates.WaitingForDealerCard) return 'SIDEBETS';
  if (next_state === BlackJackStates.PlayerEndTurn) return 'SIDEBETS';
  if (next_state === BlackJackStates.WaitingForSplitCards) return 'SIDEBETS';

  return 'LOCKED';
};
