import { useGameContext } from '@blackjack/ui/contexts';
import { BlackJackStates, PlayerAction } from '@blackjack/models';

import {
  PlayerBetModal,
  PlayerActionModal,
  Win,
  Insurance,
} from './central-modal';

/**
 * Component handles the game central UI.
 * NOTE: All props from the component are spread directly into a child, check Bet before modification.
 * @param props
 * @constructor
 */
const GameInfo = () => {
  const {
    gameState,
    handlePlayerAction,
    handlePlayerInsurance,
    getPlayerById,
  } = useGameContext();

  const player = () => {
    const user = gameState.user;
    if (!user) return;

    return getPlayerById(user.id);
  };

  /**
   * Shown when the players are allowed to bet.
   * NOTE: Currently all props in this component are spread into the Bet!
   */
  if (gameState.currentState.next_state === BlackJackStates.WaitingForBets) {
    return <PlayerBetModal />;
  }

  /**
   * Shown to players if the dealer deals an Ace as their first card.
   */
  if (
    gameState.currentState.next_state === BlackJackStates.WaitingForInsurance &&
    player() &&
    player()?.lastAction !== PlayerAction.INSURANCE &&
    gameState.insuranceInfo
  ) {
    const endTime =
      gameState.insuranceInfo.startTime + gameState.insuranceInfo.duration;
    return (
      <Insurance
        endTime={endTime}
        handlePlayerInsurance={handlePlayerInsurance}
      />
    );
  }

  /**
   * Game state shown to the current player when it's their turn to Hit / Stand etc.
   * This UI can be skipped in the event that the player used the pre action UI.
   */
  if (
    gameState.currentState.next_state === BlackJackStates.WaitingForPlayerAction
  ) {
    const endTime =
      (gameState.currentAction?.start_time || 0) +
      (gameState.currentAction?.duration || 0);

    const isPlayerSeat = gameState.userSeatIds.includes(
      gameState.currentState.seat_id
    );

    return (
      isPlayerSeat &&
      endTime &&
      gameState.currentAction && (
        <PlayerActionModal
          actions={gameState.currentAction.actions}
          seatId={gameState.currentState.seat_id}
          endTime={endTime}
          handlePlayerAction={handlePlayerAction}
        />
      )
    );
  }

  /**
   * Shown on the GameEnd event to show whether a player Won or a PUSH was in play.
   * Not shown if a player loses.
   */
  if (
    gameState.currentState.next_state === BlackJackStates.GameEnd &&
    gameState.roundInfo?.total_won &&
    gameState.roundInfo.total_won > 0
  ) {
    const currentPlayer = player();

    return currentPlayer && <Win totalWon={gameState.roundInfo.total_won} />;
  }
};

export { GameInfo };
