import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DoubleSide, MeshStandardMaterial } from 'three';
import { MathUtils, degToRad } from 'three/src/math/MathUtils';

export interface ArcShapeProps {
  innerRadius: number;
  outerRadius: number;
  segments: number;
  startAngle: number;
  length: number;
}

export interface CircleShapeProps {
  radius: number;
}

export interface ClickableShapeProps {
  type: 'circle' | 'arc';
  args: ArcShapeProps | CircleShapeProps;
  color?: string;
  opacity?: number;
  pulsate?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const CircleShape = (props: CircleShapeProps) => {
  return <circleGeometry args={[props.radius / 2, 32]} />;
};

export const ArcShape = (props: ArcShapeProps) => {
  const startAngle = useMemo(
    () => degToRad(props.startAngle),
    [props.startAngle]
  );
  const length = useMemo(() => degToRad(props.length), [props.length]);
  return (
    <ringGeometry
      args={[
        props.innerRadius,
        props.outerRadius,
        props.segments,
        2,
        startAngle,
        length,
      ]}
    />
  );
};

export const ClickableShape = (props: ClickableShapeProps) => {
  const {
    color = 'white',
    opacity = 0.0,
    pulsate = false,
    disabled = false,
  } = props;
  const [desiredOpacity, setDesiredOpacity] = useState(props.opacity || 1.0);
  const matRef = useRef<MeshStandardMaterial>(null!);

  const onHover = () => {
    if (!disabled) {
      setDesiredOpacity(0.25);
      document.body.style.cursor = 'pointer';
    }
  };

  const onHoverOut = () => {
    setDesiredOpacity(0.0);
    document.body.style.cursor = 'auto';
  };

  useEffect(() => {
    setDesiredOpacity(opacity);
  }, [opacity]);

  useEffect(() => {
    setDesiredOpacity(0);
  }, [disabled]);

  useFrame((state) => {
    if (pulsate) {
      matRef.current.opacity = MathUtils.lerp(
        0.1,
        0.2,
        Math.sin(state.clock.getElapsedTime() * 3)
      );
    } else {
      matRef.current.opacity = MathUtils.lerp(
        matRef.current.opacity,
        desiredOpacity,
        0.05
      );
    }
  });

  return (
    <mesh
      onClick={disabled ? undefined : props.onClick}
      position={[0, 0, 0.0]}
      rotation={[0, 0, 0]}
      scale={[1.0, 1.0, props.type === 'arc' ? -1 : 1]}
      receiveShadow={true}
      castShadow={false}
      onPointerOver={onHover}
      onPointerOut={onHoverOut}
    >
      {props.type === 'circle' ? (
        <CircleShape {...(props.args as CircleShapeProps)} />
      ) : (
        <ArcShape {...(props.args as ArcShapeProps)} />
      )}
      <meshStandardMaterial
        ref={matRef}
        color={color}
        transparent
        fog={false}
        depthWrite={false}
        opacity={disabled ? 0 : opacity}
        side={DoubleSide}
      />
    </mesh>
  );
};
