import { BlackJackStates, GameState } from '@blackjack/models';
import { useEffect, useState } from 'react';

export const useGameMessageHook = (
  gameState: GameState,
  totalSeats: number,
  isReady: boolean
) => {
  const [gameMessage, setGameMessage] = useState({
    show: false,
    message: '',
  });

  useEffect(() => {
    const checkSeated = gameState.players.filter(
      (p) => p.user_id === gameState.user?.id
    );

    const betPlayer = gameState.roundInfo?.players?.filter(
      (p) => p.user_id === gameState.user?.id
    );

    //Displays a popup when user joins the table.
    if (
      checkSeated.length === 0 &&
      gameState.players.length <= totalSeats &&
      isReady
    ) {
      setGameMessage({
        show: true,
        message: 'Please take a seat.',
      });
    } else {
      setGameMessage({ show: false, message: '' });
    }

    //Displays a popup if all the seats are pre occupied.
    if (checkSeated.length === 0 && gameState.players.length === totalSeats) {
      setGameMessage({
        show: true,
        message: 'All the seats are filled. Please wait till next round.',
      });
    }

    //Displays a popup if player join the session in the middle of the game.
    if (
      checkSeated.length > 0 &&
      betPlayer?.length === 0 &&
      gameState.currentState.next_state !== BlackJackStates.FinishedBetting &&
      gameState.currentState.next_state !== BlackJackStates.GameEnd &&
      gameState.currentState.next_state !== BlackJackStates.WaitingForBets
    ) {
      setGameMessage({
        show: true,
        message: 'Game in progress. Please wait till next round.',
      });
    }
  }, [
    gameState.players,
    gameState.user,
    gameState.roundInfo,
    gameState.currentState,
    totalSeats,
    isReady,
  ]);

  return gameMessage;
};
