import { createEvent } from '@shared/events';

export enum FrontendEvents {
  CLEAR_PRE_ACTION = 'clear_pre_action',
}

export const createClearPreActionEvent = (seatId: number) => {
  return createEvent(FrontendEvents.CLEAR_PRE_ACTION, { seat_id: seatId })[1];
};

export type ClearPreActionEvent = ReturnType<typeof createClearPreActionEvent>;
