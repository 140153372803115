import { IPlayerScore, PlayerAction, PlayerState } from '@blackjack/models';
import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';

import { ReactComponent as BlackJackSVG } from '../../assets/icons/Blackjack.svg';
import { ReactComponent as WinSVG } from '../../assets/icons/Win.svg';
import { ReactComponent as PushSVG } from '../../assets/icons/Push.svg';
import { ReactComponent as BustSVG } from '../../assets/icons/Bust.svg';
import { ReactComponent as DoubleSVG } from '../../assets/icons/Double.svg';
import { ReactComponent as SplitSVG } from '../../assets/icons/Split.svg';
import { ReactComponent as HitSVG } from '../../assets/icons/Hit.svg';
import { ReactComponent as StandSVG } from '../../assets/icons/Stand.svg';

export interface ScoreProps {
  score: IPlayerScore;
  selected?: boolean;
  lastAction?: PlayerAction | null;
  playerState?: PlayerState;
  updated?: number | null; // Last time the action was update
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Chakra Petch';

  pointer-events: auto;
  height: 3rem;

  width: 6rem;
  border-radius: 1.5rem;
  background-color: black;
  color: white;
  font-size: 1.6rem;
  padding-top: 0.1rem;
  text-shadow: none;
  box-sizing: border-box;

  border: 0.25rem solid rgba(255, 255, 255, 0.2);

  transition: border-color 200ms, background-color 200ms,
    color 200ms ease-in-out;

  margin-bottom: 1rem;

  &.selected {
    border-color: rgba(100, 255, 255, 1);
  }

  &.STAND {
    background-color: rgba(238, 41, 35, 1);
    color: white;
    font-weight: 700;
    svg {
      height: 1.4rem;
    }
  }

  &.HIT {
    background-color: rgba(110, 194, 120, 1);
    color: black;
    font-weight: 700;
    svg {
      height: 1.4rem;
    }
  }

  &.SPLIT {
    background-color: rgba(30, 50, 255, 1);
    color: white;
    svg {
      height: 1.4rem;
    }
  }

  &.DOUBLE {
    background-color: rgba(0, 0, 0, 1);
    color: white;
    svg {
      height: 1.4rem;
    }
  }

  &.blackjack,
  &.bust {
    background-color: black;
    color: white;

    overflow: hidden;

    svg {
      height: 1.8rem;
    }
  }

  &.win,
  &.push,
  &.loss {
    background-color: black;
    color: white;
    svg {
      height: 1.6rem;
      fill: #ffffff;
    }
  }

  .loss-icon {
    font-size: 3rem;
    overflow: hidden;
    font-weight: 700;
    color: white;
  }
`;

export const Score = (props: ScoreProps) => {
  const { score, selected, lastAction, playerState, updated } = props;
  const [showLastAction, setShowLastAction] = useState(false);

  const className = useMemo(() => {
    const cln = selected ? ' selected' : '';
    if (score.blackjack) return `blackjack${cln}`;
    if (score.max > 21) return `bust${cln}`;
    return `${cln} ${lastAction} ${showLastAction ? 'last-action' : ''}`;
  }, [score, selected, lastAction, showLastAction]);

  const value = useMemo(() => {
    if (playerState === PlayerState.WIN) return <WinSVG />;
    if (playerState === PlayerState.PUSH) return <PushSVG />;
    if (playerState === PlayerState.LOSS)
      return <div className="loss-icon">X</div>;

    if (score.blackjack) return <BlackJackSVG />;
    if (score.max > 21) return <BustSVG />;

    if (showLastAction) {
      switch (lastAction) {
        case PlayerAction.HIT:
          return <HitSVG />;
        case PlayerAction.STAND:
          return <StandSVG />;
        case PlayerAction.SPLIT:
          return <SplitSVG />;
        case PlayerAction.DOUBLE:
          return <DoubleSVG />;
      }
    }
    return score.text;
  }, [score, lastAction, showLastAction, playerState]);

  useEffect(() => {
    if (lastAction) {
      setShowLastAction(true);
      setTimeout(() => {
        setShowLastAction(false);
      }, 3000);
    }
  }, [lastAction, updated]);

  return (
    <Container className={`score ${className} ${playerState}`}>
      {value}
    </Container>
  );
};
