import { SettingsButtonsRow } from '@blackjack/ui/components';
import { useGameContext } from '@blackjack/ui/contexts';

import styled from '@emotion/styled';
import { useCallback, useMemo, useState } from 'react';
import { Hand } from './hand';
import { TableLobbyButtons } from './table-lobby-buttons';

import { useAuthentication } from '@shared/ui/hooks';
import { LogoutPopup } from './logout-popup';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.4rem;

  .bottom-right-button-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 5%;
  }
`;

const Hands = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 2.8rem;
`;

const RightColumn: React.FC = () => {
  const { gameState, isOwnSeat, muted, toggleMute } = useGameContext();
  const { clearAuthentication } = useAuthentication();

  const [showSettings, setShowSettings] = useState(false);

  const activeSeat = gameState.currentAction?.seat;

  const isPlayersHand = useCallback(() => {
    if (gameState.currentAction && activeSeat !== undefined) {
      return isOwnSeat(activeSeat);
    }
    return false;
  }, [activeSeat, gameState.currentAction, isOwnSeat]);

  const player = useMemo(() => {
    return gameState.players.find((p) => p.seat === activeSeat);
  }, [gameState.players, activeSeat]);

  const currentHand = gameState.currentState.hand;

  const playerCards = useMemo(() => {
    if (isPlayersHand()) {
      const player = gameState.players.find((p) => p.seat === activeSeat);
      return player?.cards[currentHand] || [];
    }
    return [];
  }, [gameState.players, currentHand, isPlayersHand, activeSeat]);

  const dealerCards = gameState?.dealer?.cards[0] ?? [];

  const handleLogout = () => {
    clearAuthentication();
    window.location.reload();
  };

  return (
    <Container>
      <SettingsButtonsRow
        onSettingsClick={() => setShowSettings(true)}
        onMuteClick={toggleMute}
        muted={muted}
      />
      <LogoutPopup
        isVisible={showSettings}
        onClose={() => setShowSettings(false)}
        onLogout={handleLogout}
      />
      <Hands>
        <Hand isDealer hand={dealerCards} score={gameState.dealer?.scores[0]} />
        <Hand
          isDealer={false}
          hand={playerCards}
          score={player?.scores[currentHand]}
        />
      </Hands>
      <div className="bottom-right-button-row">
        <TableLobbyButtons>+TABLE</TableLobbyButtons>
        <TableLobbyButtons leftSide={false}>LOBBY</TableLobbyButtons>
      </div>
    </Container>
  );
};

RightColumn.defaultProps = {};

export { RightColumn };
