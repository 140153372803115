import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { keyframes } from '@emotion/react';
import { CornerBox } from '../layout';

const anim = keyframes`
  from {
    opacity: 0;
    transform: translateY(20rem) scale(0.1);
  }
  to {
    opacity: 1;
  }
`;

const Box = styled(CornerBox)`
  width: 62.2rem;

  height: 26.4rem;

  margin: 0 auto;
  opacity: 1;
  animation: ${anim} 500ms;
`;

type Props = PropsWithChildren & {
  className?: string;
};

const Modal = (props: Props) => {
  const { className, children } = props;

  return (
    <Box
      className={className}
      topLeft
      bottomLeft
      bottomRight
      topRight
      cornerSize={'3.5rem'}
    >
      {children}
    </Box>
  );
};

export { Modal };
