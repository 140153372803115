import { useEffect, useRef } from 'react';
import 'webrtc-adapter';
import styled from '@emotion/styled';
import { useWebsocketContext } from '@shared/ui/contexts';

const Container = styled.div`
  display: block;
  position: relative;

  width: 800px;
  height: 450px;

  border: 1px solid red;

  .video-player {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

interface StreamerList {
  ids: string[];
}

export interface VideoStreamPixelStreamProps {
  muted?: boolean;
  onReady: () => void;
}

export const VideoStreamPixelStream = (props: VideoStreamPixelStreamProps) => {
  const { muted, onReady } = props;
  const videoParent = useRef<HTMLVideoElement>(null);

  const { socket: clientSocket } = useWebsocketContext();

  useEffect(() => {
    const peerConnection = new RTCPeerConnection({
      iceServers: [
        { urls: 'stun:stun.stunprotocol.org:3478' },
        { urls: 'stun:stun.l.google.com:19302' },
      ],
    });

    peerConnection.ontrack = (event) => {
      if (videoParent.current && event.track.kind === 'video') {
        videoParent.current.srcObject = event.streams[0];
        videoParent.current.play();
        onReady();
      }
    };

    peerConnection.onicecandidate = (event) => {
      console.log('Got candidate', event);
      if (peerConnection.remoteDescription !== null) {
        if (event.candidate !== null) {
          clientSocket.emit('streamer:iceCandidate', {
            candidate: event.candidate,
            type: event.type,
          });
        }
      }
    };

    const onStreamerList = (list: StreamerList) => {
      if (list.ids.length === 1) {
        clientSocket.emit('streamer:subscribe', { id: list.ids[0] });
      }
    };

    const onOffer = async (offer: RTCSessionDescriptionInit) => {
      console.log('On Offer', offer);

      if (peerConnection.remoteDescription) return;

      const desc = new RTCSessionDescription(offer);

      // if (peerConnection.signalingState === 'stable') return;
      // if (makingOffer) return;
      //
      console.log('Remote', desc);

      await peerConnection.setRemoteDescription(desc);

      const answer = await peerConnection.createAnswer();

      console.log('answer', answer);

      await peerConnection.setLocalDescription(answer);

      console.log(peerConnection.localDescription);

      clientSocket.emit('streamer:answer', {
        sdp: peerConnection.localDescription?.sdp,
        type: peerConnection.localDescription?.type,
      });
    };

    const onAnswer = (answer: string) => {
      console.log('Answer', answer);
    };

    const onIceCandidate = async (message: {
      candidate: RTCIceCandidateInit;
    }) => {
      console.log('Ice Candidate', message);
      await peerConnection.addIceCandidate(
        new RTCIceCandidate(message.candidate)
      );
    };

    clientSocket.on('streamer:streamer-list', onStreamerList);
    clientSocket.on('streamer:offer', onOffer);
    clientSocket.on('streamer:answer', onAnswer);
    clientSocket.on('streamer:iceCandidate', onIceCandidate);

    clientSocket.emit('streamer:list-streamers', {});

    return () => {
      peerConnection.close();
      clientSocket.off('streamer:streamer-list', onStreamerList);
      clientSocket.off('streamer:answer', onAnswer);
      clientSocket.off('streamer:offer', onOffer);
      clientSocket.off('streamer:iceCandidate', onIceCandidate);
    };
  }, [clientSocket, onReady]);

  return (
    <Container>
      <video
        muted={muted}
        className="video-player"
        ref={videoParent}
        autoPlay
        playsInline
      ></video>
    </Container>
  );
};
