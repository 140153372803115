import styled from '@emotion/styled';
import { Director, View } from '@millicast/sdk';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

export interface VideoStreamMillicastProps {
    accountId: string;
    name: string;
    muted?: boolean;
    onReady: () => void;
}

const Video = styled.div`
    width: 100%;
    height: 100%;

    background-color: black;

    box-sizing: border-box;

    transition: backdrop-filter 1s ease-in-out;

    &.loading {
        backdrop-filter: blur(10px);

        video {
            opacity: 0;
        }
    }

    &.active {
        video {
            filter: none;
        }
    }

    video {
        filter: blur(6px);
        opacity: 1;
        width: 100%;
        height: 100%;
        transition: filter 1s ease-in-out;
        position: relative;
        flex: 1;
    }
`;

export const VideoStreamMillicast = (props: VideoStreamMillicastProps) => {
    const { accountId, name, muted = false, onReady } = props;

    const videoRef = useRef<HTMLVideoElement>(null);

    const [hasInteracted, setHasInteracted] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const tokenGenerator = useCallback(
        () =>
            Director.getSubscriber({
                streamName: name,
                streamAccountId: accountId,
            }),
        [name, accountId]
    );

    useEffect(() => {
        const millicastView = new View(name, tokenGenerator, undefined, true);

        const connect = async () => {
            console.log('Started', name);

            millicastView.on('broadcastEvent', (event) => {
                console.log('bce', event);
                if (event.name === 'active') {
                    setIsActive(true);
                } else if (event.name === 'inactive') {
                    setIsActive(false);
                }
            });

            millicastView.on('track', (event) => {
                console.log('Stream has started', event);
                if (videoRef.current && event.track.kind) {
                    console.log('Video stream track');
                    videoRef.current.srcObject = event.streams[0];
                    videoRef.current.hidden = false;
                    videoRef.current.autoplay = true;
                    onReady();
                }
            });

            videoRef.current?.addEventListener('loadedmetadata', () => {
                console.log('Video fully loaded');
            });

            try {
                await millicastView.connect();
            } catch (err) {
                if (!millicastView.isActive()) {
                    console.log(
                        'Stream is not live, the broadcast will begin soon.'
                    );
                }

                console.log('Connection failed: ', err);
                await millicastView.reconnect();
            }
        };

        connect();

        return () => {
            millicastView.removeAllListeners();
            millicastView.stop();
        };
    }, [name, tokenGenerator, onReady]);

    useEffect(() => {
        const handleClick = () => {
            setHasInteracted(true);
            window.removeEventListener('click', handleClick);
        };

        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <Video className={clsx({ active: isActive })}>
            <video
                ref={videoRef}
                autoPlay
                muted={!hasInteracted ? true : muted}
            />
        </Video>
    );
};
