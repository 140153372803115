import styled from '@emotion/styled';
import { ICard, IPlayerScore } from '@blackjack/models';
import { keyframes } from '@emotion/react';
import { MobileCard } from '@blackjack/ui/components';

const anim = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;
const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
`;

const HandRow = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* flex: 1; */

  transition: width 200ms ease-in-out;

  .card {
    margin-right: -3.8rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.9);

    animation: ${anim} 250ms ease-in-out;
  }

  .card:last-child {
    margin-right: 0;
  }
  .score {
    margin-right: 2rem;
  }
`;

interface Props {
  score?: IPlayerScore;
  hand: ICard[];
}

const CardHand = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { hand, score } = props;

  return (
    <Container>
      <HandRow>
        {/* {score && <Score score={score} />} */}
        {hand.map((card) => (
          <MobileCard
            key={card.uuid}
            face={card.face}
            value={card.value}
            flipped
            style={{
              position: 'relative',
            }}
          />
        ))}
      </HandRow>
    </Container>
  );
};

export { CardHand, Props };
