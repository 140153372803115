import styled from '@emotion/styled';
import { ReactNode } from 'react';

import { CornerBox } from '@blackjack/ui/components';

const Container = styled.div<{ leftSide: boolean }>`
  display: flex;
  font-size: 2rem;
  height: 6rem;
  width: 12rem;

  align-items: center;
  justify-content: center;
`;

interface Props {
  leftSide?: boolean;
  children: ReactNode;
}

const TableLobbyButtons = (props: Props) => {
  const { leftSide = true, children } = props;

  return (
    <CornerBox
      bottomLeft={leftSide}
      bottomRight={!leftSide}
      topLeft={!leftSide}
      topRight={leftSide}
      cornerSize={'2rem'}
    >
      <Container leftSide={leftSide}>
        <span>{children}</span>
      </Container>
    </CornerBox>
  );
};

export { TableLobbyButtons, Props };
