import styled from '@emotion/styled';

import { GameInfo } from '../components/mobile/game-info';
import { PlayerInfo } from '../components/mobile/player-info';
import { VirtualTable } from '../components/mobile/virtual-table';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MobileTop = styled.div`
  width: 100%;
  height: 42%;
  display: flex;
`;

const MobileMidSection = styled.div`
  width: 100%;
  height: 45%;
  display: flex;

  border: 1px solid red;
  box-sizing: border-box;
`;

const MobileFooter = styled.div`
  width: 100%;
  height: 13%;
  display: flex;
`;

export const MobileAppLayout = () => {
  return (
    <Container>
      <MobileTop>
        <VirtualTable />
      </MobileTop>
      <MobileMidSection>
        <GameInfo />
      </MobileMidSection>
      <MobileFooter>
        <PlayerInfo />
      </MobileFooter>
    </Container>
  );
};
