import { useGameContext } from '@blackjack/ui/contexts';
// TODO
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BlackJackStates } from '@blackjack/models';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PlayerBet } from './mobile-player-bet';
import { PlayerAction } from './mobile-player-action';

export const GameInfo = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { gameState } = useGameContext();

  /**
   * Shown when the players are allowed to bet.
   * NOTE: Currently all props in this component are spread into the Bet!
   */
  // if (gameState.currentState.next_state === BlackJackStates.WaitingForBets) {
  //   return <PlayerBet />;
  // }

  return <PlayerAction />;
};
