import {
    useContext,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    ReactNode,
} from 'react';

import {
    BlackJackStates,
    PlayerAction,
    PlayerBet,
    UserBet,
    createPlayerLeaveSeatEvent,
} from '@blackjack/models';

import { useWebsocketContext } from '@shared/ui/contexts';
import { useBetHook } from '@blackjack/ui/hooks';

import { useGameContext } from './game-context';

interface LogicStateContextProps {
    totalCredits: number;
    canRepeat: boolean;
    activeChip: number | null;
    isReady: boolean;
    audioRef: React.RefObject<HTMLAudioElement> | null;
    userTotalSeats: number;
    betHistory: UserBet[];
    baseBetTotal: number;
    sideBetTotals: number;
}

interface LogicActionContextProps {
    handlePlayerAction: (
        seatId: number,
        action: PlayerAction,
        section?: PlayerBet
    ) => void;
    handlePlayerClick: (seatId: number) => void;
    handleVideoReady: () => void;
    setActiveChip: React.Dispatch<React.SetStateAction<number | null>>;
    undoBet: () => void;
    repeatLast: () => void;
    doubleBets: () => void;
}

/**
 * Separate contexts for state and action handlers
 */
const LogicStateContext = createContext<LogicStateContextProps | undefined>(
    undefined
);
const LogicActionContext = createContext<LogicActionContextProps | undefined>(
    undefined
);

interface LogicProviderProps {
    children: ReactNode;
}

const LogicContextProvider = (props: LogicProviderProps) => {
    const { children } = props;

    /**
     *
     */
    const {
        handlePlayerSit,
        gameState,
        seatIsFree,
        handlePlayerBets,
        // handlePlayerAction: handleRoundAction,
        // muted,
        // previousWin,
    } = useGameContext();

    const { socket, connected } = useWebsocketContext();

    const {
        increaseBet,
        undoBet,
        clearBetHistory,
        repeatLast,
        betHistory,
        baseBetTotal,
        sideBetTotals,
        totalForType,
        storeLastBets,
        lastBets,
        doubleBets,
    } = useBetHook();

    const [activeChip, setActiveChip] = useState<number | null>(null);

    const userTotalSeats = gameState.userSeatIds.length;

    const [videoReady, setVideoReady] = useState(false);

    const [isReady, setIsReady] = useState(false);

    const audioRef = useRef<HTMLAudioElement>(null);

    /**
     *  USE EFFECTS
     */
    useEffect(() => {
        if (gameState.currentState.next_state === BlackJackStates.GameEnd) {
            storeLastBets(true);
        }
    }, [gameState.currentState.next_state, storeLastBets]);

    useEffect(() => {
        if (
            gameState.currentState.next_state === BlackJackStates.WaitingForBets
        ) {
            handlePlayerBets(betHistory);
        }
    }, [betHistory, handlePlayerBets, gameState.currentState]);

    useEffect(() => {
        if (userTotalSeats === 0) clearBetHistory();
    }, [clearBetHistory, userTotalSeats]);

    useEffect(() => {
        if (audioRef.current) audioRef.current.volume = 0.6;
    }, []);

    useEffect(() => {
        if (connected && gameState.gameInfo) {
            //setIsReady(gameState.gameInfo.streamData ? videoReady : true);
            setIsReady(true);
        } else {
            if (!connected) {
                const timer = setTimeout(() => {
                    setIsReady(false);
                }, 5000);

                return () => {
                    clearTimeout(timer);
                };
            }
        }
    }, [connected, gameState.gameInfo, videoReady]);

    useEffect(() => {
        const clickListener = () => {
            if (audioRef.current) audioRef.current.play();
        };

        window.addEventListener('click', clickListener);
        return () => {
            window.removeEventListener('click', clickListener);
        };
    }, []);

    /**
     * Private Function: Player Sit Handler
     */
    const handleSeatClick = useCallback(
        (seatId: number) => {
            if (seatIsFree(seatId)) {
                handlePlayerSit(seatId);
            }
        },
        [handlePlayerSit, seatIsFree]
    );

    /**
     *
     */
    const handlePlayerAction = useCallback(
        (seatId: number, action: PlayerAction, section?: PlayerBet) => {
            if (action === PlayerAction.SIT) {
                if (
                    baseBetTotal > 0 &&
                    baseBetTotal * (userTotalSeats + 1) + sideBetTotals >
                        (gameState.user?.credits || 0)
                ) {
                    // Can't sit if it will cause you to go into negative credits
                    return;
                }
                handleSeatClick(seatId);
            } else if (action === PlayerAction.BET) {
                if (activeChip && activeChip > 0) {
                    const chipTotal =
                        section === PlayerBet.BASE
                            ? activeChip * userTotalSeats
                            : activeChip;
                    // Stop user betting more credits than they have available
                    if (
                        chipTotal +
                            baseBetTotal * userTotalSeats +
                            sideBetTotals >
                        (gameState.user?.credits || 0)
                    ) {
                        return;
                    }
                    if (section === PlayerBet.BASE) {
                        if (
                            baseBetTotal + activeChip >
                            (gameState?.gameInfo?.max_bet || 0)
                        )
                            return;
                        if (
                            baseBetTotal + activeChip <
                            (gameState?.gameInfo?.min_bet || 0)
                        )
                            return;
                    } else if (section) {
                        const total =
                            totalForType(section, seatId) + activeChip;
                        if (total > (gameState?.gameInfo?.max_side_bet || 0)) {
                            return;
                        }
                        if (total < (gameState?.gameInfo?.min_side_bet || 0)) {
                            return;
                        }
                    }

                    increaseBet(seatId, activeChip, section || PlayerBet.BASE);
                }
            }
        },
        [
            baseBetTotal,
            userTotalSeats,
            sideBetTotals,
            gameState.user?.credits,
            activeChip,
            gameState?.gameInfo?.min_bet,
            gameState?.gameInfo?.max_bet,
            gameState?.gameInfo?.max_side_bet,
            gameState?.gameInfo?.min_side_bet,
            handleSeatClick,
            increaseBet,
            totalForType,
        ]
    );

    /**
     *
     */
    const handlePlayerClick = useCallback(
        (seatId: number) => {
            socket
                .emitWithResponse(createPlayerLeaveSeatEvent(seatId))
                .then((res) => console.log(res));
        },
        [socket]
    );

    /**
     *
     */
    const totalCredits = useMemo(() => {
        if (
            gameState.currentState.next_state === BlackJackStates.WaitingForBets
        ) {
            return (
                (gameState.user?.credits || 0) -
                (baseBetTotal * userTotalSeats + sideBetTotals)
            );
        }
        return gameState.user?.credits || 0;
    }, [
        gameState.user,
        gameState.currentState,
        baseBetTotal,
        sideBetTotals,
        userTotalSeats,
    ]);

    /**
     *
     */
    const canRepeat = useMemo(() => {
        if (lastBets.length === 0) return false;
        const totalCredits = gameState.user?.credits || 0;
        if (totalCredits <= 0) return false;

        const totalLast = lastBets.reduce((acc, cur) => {
            if (cur.type === PlayerBet.BASE)
                return acc + cur.amount * userTotalSeats;
            return acc + cur.amount;
        }, 0);

        if (totalLast > totalCredits) return false;

        return true;
    }, [lastBets, gameState.user, userTotalSeats]);

    /**
     *
     */
    const handleVideoReady = useCallback(() => {
        setVideoReady(true);
    }, []);

    /**
     * These values change and are memoized for optimisation
     */
    const stateValues = useMemo(() => {
        return {
            totalCredits,
            canRepeat,
            activeChip,
            isReady,
            audioRef,
            userTotalSeats,
            betHistory,
            baseBetTotal,
            sideBetTotals,
        };
    }, [
        totalCredits,
        canRepeat,
        activeChip,
        isReady,
        audioRef,
        userTotalSeats,
        betHistory,
        baseBetTotal,
        sideBetTotals,
    ]);

    /**
     * These values don't change
     */
    const actionHandlers = {
        handlePlayerAction,
        handlePlayerClick,
        handleVideoReady,
        setActiveChip,
        undoBet,
        repeatLast,
        doubleBets,
    };

    return (
        <LogicActionContext.Provider value={actionHandlers}>
            <LogicStateContext.Provider value={stateValues}>
                {children}
            </LogicStateContext.Provider>
        </LogicActionContext.Provider>
    );
};

const useLogicStateContext = (): LogicStateContextProps => {
    const context = useContext(LogicStateContext);
    if (!context) {
        throw new Error('LogicStateContext error');
    }
    return context;
};

const useLogicActionContext = (): LogicActionContextProps => {
    const context = useContext(LogicActionContext);
    if (!context) {
        throw new Error('LogicActionContext error');
    }
    return context;
};

export { LogicContextProvider, useLogicStateContext, useLogicActionContext };
