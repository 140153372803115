import styled from '@emotion/styled';

import { BigButton, CornerBox, SvgButton } from '@blackjack/ui/components';

const Container = styled.div`
  position: fixed;
  z-index: 10001;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 192rem;
  height: 108rem;

  margin-left: calc(-192rem / 2);
  left: 50%;
  top: 0;

  pointer-events: auto;

  &.hide {
    display: none;
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 4rem 4rem;

    pointer-events: auto;

    .button {
      height: 5rem;
    }
  }
`;

const Wrapper = styled.div`
  width: 40rem;
`;

export interface LogoutPopupProps {
  isVisible: boolean;
  onLogout: () => void;
  onClose: () => void;
}

export const LogoutPopup = (props: LogoutPopupProps) => {
  const { isVisible, onLogout, onClose } = props;
  return (
    <Container onClick={onClose} className={isVisible ? 'show' : 'hide'}>
      <Wrapper>
        <CornerBox topRight bottomLeft topLeft bottomRight cornerSize={'2rem'}>
          <div className="form">
            <SvgButton onClick={onLogout} className="button logout">
              <BigButton
                text="Logout"
                backgroundColor="var(--button-red-bg-color)"
              />
            </SvgButton>
            <SvgButton onClick={onClose} className="button close">
              <BigButton
                text="Close"
                backgroundColor="var(--button-default-bg-color)"
              />
            </SvgButton>
          </div>
        </CornerBox>
      </Wrapper>
    </Container>
  );
};
