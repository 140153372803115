import React, { Suspense, useMemo } from 'react';
import { useSpring, animated } from '@react-spring/three';
import { Text as ThreeText, useGLTF, useTexture } from '@react-three/drei';
import { degToRad } from 'three/src/math/MathUtils';
import { useThree } from '@react-three/fiber';

import { GLTF } from 'three-stdlib';
import { Color } from 'three';

import chipSound from '../../assets/audio/single-chip.mp3';
import chipSoundReverse from '../../assets/audio/single-chip-reverse.mp3';
import sideBetSound from '../../assets/audio/sidebets.mp3';
import sideBetSoundReverse from '../../assets/audio/sidebets-reverse.mp3';

export interface ChipProps {
    amount: number;
    isAnimated?: boolean;
    isVisible?: boolean;
    position?: [number, number, number];
    scale?: number;
    rotateZ?: number;
    direction?: 'to-player' | 'to-dealer';
    muted?: boolean;
    chipType?: 'base' | 'side';
    onFinish?: () => void;
}

type GLTFResult = GLTF & {
    nodes: {
        Circle: THREE.Mesh;
    };
    materials: {
        Material: THREE.MeshStandardMaterial;
    };
};

const CHIP_COLORS = [
    '#5ebe61', // 10
    '#4dbb85', // 50
    '#56bfab',
    '#4481c4',
    '#4550a6',
    '#35268a',
];

const ROTATION: [number, number, number] = [degToRad(90), 0, 0];

const ChipComponent = (props: ChipProps) => {
    const {
        amount,
        isAnimated = false,
        isVisible = false,
        position = [0, 0, 0],
        scale = 1,
        rotateZ = 0,
        direction = 'to-player',
        muted = false,
        chipType = 'base',
        onFinish = () => null,
    } = props;

    const invert = useTexture('./assets/table/chip/chip-texture.png');

    const { nodes, materials } = useGLTF(
        './assets/table/chip/chip.gltf'
    ) as GLTFResult;

    const handleFinish = () => {
        if (isAnimated) onFinish();

        if (!muted) {
            if (isVisible) {
                const sound = new Audio(
                    chipType === 'base' ? chipSound : sideBetSound
                );
                sound.volume = 0.4;
                sound.play();
            } else {
                const sound = new Audio(
                    chipType === 'base' ? chipSoundReverse : sideBetSoundReverse
                );
                sound.volume = 0.4;
                sound.play();
            }
        }
    };

    const { x, y, z } = useSpring({
        from: {
            x: 0.0,
            y: direction === 'to-player' ? 0.5 : -0.5,
            z: 0.0,
            opacity: 1.0,
        },
        to: {
            x: position[0],
            y: position[1],
            z: (isAnimated ? -0.001 : 0.0) + position[2],
            opacity: isAnimated ? 0.0 : 1.0,
        },
        config: {
            tension: 100,
            friction: 12,
            mass: 1,
            clamp: true,
        },
        immediate: !isAnimated,
        reverse: !isVisible,
        onRest: () => (isAnimated ? handleFinish() : null),
    });

    const color = useMemo(() => {
        if (amount < 5) {
            return CHIP_COLORS[0];
        } else if (amount < 10) {
            return CHIP_COLORS[1];
        } else if (amount < 25) {
            return CHIP_COLORS[2];
        } else if (amount < 100) {
            return CHIP_COLORS[3];
        } else if (amount < 500) {
            return CHIP_COLORS[4];
        }
        return CHIP_COLORS[5];
    }, [amount]);

    useThree(() => {
        materials.Material.emissive.set(new Color(color));
    });

    const fontSize = useMemo(() => {
        switch (amount.toString().length) {
            case 1:
            case 2:
                return 0.9;
            case 3:
                return 0.55;
            case 4:
                return 0.45;
            default:
                return 1.0 - amount.toString().length * 0.12;
        }
    }, [amount]);

    if (!isVisible && !isAnimated) return null;

    return (
        <Suspense fallback={null}>
            <animated.group
                dispose={null}
                scale={0.09 * scale}
                position-x={x}
                position-y={y}
                position-z={z}
                rotation-z={rotateZ}
            >
                {/* Using 2 of the same meshes to get multiple materials because I couldn't work out how to do it properly or its not supported */}
                <mesh
                    rotation={ROTATION}
                    position={[0, 0, -0.12]}
                    geometry={nodes.Circle.geometry}
                >
                    <meshStandardMaterial
                        map={invert}
                        color={color}
                        alphaMap={invert}
                        transparent={true}
                    />
                </mesh>
                <mesh
                    castShadow
                    receiveShadow
                    rotation={ROTATION}
                    position={[0, 0, -0.12]}
                    geometry={nodes.Circle.geometry}
                >
                    <meshStandardMaterial color={'#fff'} />
                </mesh>
                <ThreeText
                    color="white"
                    fillOpacity={1.0}
                    anchorX="center"
                    anchorY="middle"
                    font={
                        'https://fonts.gstatic.com/s/chakrapetch/v11/cIf6MapbsEk7TDLdtEz1BwkWn6pl.ttf'
                    }
                    fontSize={fontSize}
                    outlineColor="#fff"
                    outlineWidth={0.002}
                    position={[0, 0, -0.24]}
                    scale={[1, -1, 1]}
                >
                    {amount || 5}
                </ThreeText>
            </animated.group>
        </Suspense>
    );
};

useGLTF.preload('./assets/table/chip/chip.gltf');
useTexture.preload('./assets/table/chip/chip-texture.png');

export const Chip = React.memo(ChipComponent);
