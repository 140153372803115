import styled from '@emotion/styled';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const VirtualTable = () => {
  return (
    <Container>
      <div>VirtualTable</div>
    </Container>
  );
};
