import styled from '@emotion/styled';
import { useMemo } from 'react';

const Container = styled.div<{ color: string; calculatedTextSize: number }>`
  /* border: 1px solid blue; */
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  cursor: pointer;

  transition: filter 200ms ease-in;

  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  svg {
    .st0 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #403e3e;

      transition: fill 200ms ease-in;
    }
    .st1 {
      fill: #ffffff;
    }
    .st2 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #f6f6f6;
    }
  }

  &.selected {
    svg {
      .st0 {
        fill: ${({ color }) => color};
      }
    }
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.4));
  }

  .amount {
    /* border: 1px solid red; */
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .chip-text {
      font-family: 'Chakra Petch', sans-serif;
      font-size: ${({ calculatedTextSize }) => `${calculatedTextSize}rem`};
      color: white;
      user-select: none;
    }
  }
`;

export interface SvgChipProps {
  isBigChip?: boolean;
  color?: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const SvgChip = (props: SvgChipProps) => {
  const {
    isBigChip = false,
    color = '#58bb61',
    value,
    selected = false,
    disabled = false,
    onClick,
  } = props;

  // Calculation to scale the text based on numbers of chars

  const calculatedTextSize = useMemo(() => {
    if (isBigChip) {
      switch (value.length) {
        case 1:
        case 2:
          return 6.8;
        case 3:
          return 4.9;
        case 4:
          return 3.8;
        case 5:
          return 3.2;
        case 6:
          return 2.5;
        default:
          return 2;
      }
    } else {
      switch (value.length) {
        case 1:
        case 2:
          return 2.6;
        case 3:
          return 1.8;
        case 4:
          return 1.4;
        case 5:
          return 1.2;
        case 6:
          return 1.0;
        default:
          return 0.8;
      }
    }
  }, [value, isBigChip]);
  const className = `${selected ? 'selected' : ''} ${
    disabled ? 'disabled' : ''
  }`;

  return (
    <Container
      color={color}
      calculatedTextSize={calculatedTextSize}
      className={className}
      onClick={disabled ? undefined : onClick}
    >
      <svg
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 700 700"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="sofGlow" height="300%" width="300%" x="-75%" y="-75%">
            <feMorphology
              operator="dilate"
              radius="4"
              in="SourceAlpha"
              result="thicken"
            />

            <feGaussianBlur in="thicken" stdDeviation="10" result="blurred" />

            <feFlood floodColor="rgb(0,186,255)" result="glowColor" />

            <feComposite
              in="glowColor"
              in2="blurred"
              operator="in"
              result="softGlow_colored"
            />

            <feMerge>
              <feMergeNode in="softGlow_colored" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <circle
          id="base-circle"
          className="st0"
          r="350"
          cx="350"
          cy="350"
          shapeRendering="geometricPrecision"
          filter="url(#softGlow)"
        />
        <path
          id="top-right-notch"
          className="st2"
          d="m 457.60805,23.34457 c 22.56376,7.432655 44.10755,17.127037 64.32092,28.843668 20.25772,11.663415 39.44224,25.464383 57.15444,41.287601 L 538.50589,138.9055 c -10.99807,12.32863 -24.63034,15.974 -39.31807,5.35719 -7.14874,-5.1798 -14.54583,-9.96047 -22.14691,-14.34201 -7.58334,-4.39041 -15.42389,-8.39943 -23.47731,-12.00045 -16.53257,-7.41492 -20.19563,-21.029627 -15.02477,-36.719802 z"
        />
        <path
          id="top-left-notch"
          className="st2"
          d="M 120.91659,93.475839 C 138.63766,77.652621 157.80444,63.851653 178.06216,52.188238 198.2844,40.471607 219.82819,30.768356 242.39195,23.34457 l 19.05148,57.864727 c 5.17973,15.681306 1.51667,29.304883 -15.00703,36.719793 -8.06229,3.60103 -15.90284,7.61005 -23.48618,12.00046 -7.60108,4.38154 -14.9893,9.16221 -22.13804,14.34201 -14.68773,10.60794 -28.32,6.97144 -39.31807,-5.35719 z"
        />
        <path
          id="left-notch"
          className="st2"
          d="M 13.312973,420.1224 C 8.461409,396.86653 6.1021474,373.38005 6.1287556,350 6.1021474,326.62882 8.461409,303.1246 13.312973,279.85986 l 59.637875,12.42619 c 16.168924,3.36155 26.138134,13.34863 24.293298,31.37149 -0.913548,8.78082 -1.365888,17.57051 -1.33928,26.34246 -0.01774,8.77195 0.434601,17.55277 1.33928,26.3336 1.853706,18.02285 -8.124374,28.00993 -24.293298,31.36261 z"
        />
        <path
          id="bottom-left-notch"
          className="st2"
          d="m 120.91659,606.52416 c 17.72107,15.81435 36.88785,29.62419 57.14557,41.27873 20.22224,11.71664 41.76603,21.41989 64.32979,28.8348 l 19.05148,-57.84699 c 5.17973,-15.69904 1.51667,-29.31375 -15.00703,-36.73753 -8.06229,-3.60103 -15.90284,-7.59231 -23.48618,-12.00046 -7.60108,-4.37267 -14.9893,-9.16221 -22.13804,-14.32427 -14.68773,-10.62568 -28.32,-6.98031 -39.31807,5.34832 z"
        />
        <path
          id="bottom-right-notch"
          className="st2"
          d="m 457.60805,676.63769 c 22.56376,-7.42378 44.10755,-17.11816 64.32092,-28.8348 20.25772,-11.65454 39.44224,-25.46438 57.15444,-41.27873 l -40.57752,-45.43853 c -10.99807,-12.32863 -24.63034,-15.974 -39.31807,-5.34832 -7.14874,5.16206 -14.54583,9.9516 -22.14691,14.32427 -7.58334,4.40815 -15.42389,8.39943 -23.47731,12.00046 -16.53257,7.42378 -20.19563,21.02962 -15.02477,36.73753 z"
        />
        <path
          id="right-notch"
          className="st2"
          d="m 686.68702,420.1224 c 4.8427,-23.25587 7.21083,-46.74235 7.18422,-70.1224 0.0266,-23.37118 -2.34152,-46.8754 -7.18422,-70.14014 l -59.63787,12.42619 c -16.17779,3.36155 -26.13814,13.34863 -24.2933,31.37149 0.90468,8.78082 1.34815,17.57051 1.33928,26.34246 0.009,8.77195 -0.44347,17.55277 -1.33928,26.3336 -1.8537,18.02285 8.10664,28.00993 24.2933,31.36261 z"
        />
        <circle
          id="top-circle"
          className="st2"
          cx="349.99554"
          cy="51.04406"
          r="20.603626"
          shapeRendering="geometricPrecision"
        />
        <circle
          id="top-left-circle"
          className="st2"
          cx="85.252408"
          cy="199.21566"
          r="20.603626"
          shapeRendering="geometricPrecision"
        />
        <circle
          id="bottom-left-circle"
          className="st2"
          cx="85.252408"
          cy="509.77547"
          r="20.603626"
          shapeRendering="geometricPrecision"
        />

        <path
          id="inner-circle-outline"
          className="st2"
          d="m 349.99557,121.02456 c 126.45109,0 228.97254,102.52274 228.97254,228.97544 0,126.4527 -102.52145,228.97544 -228.97254,228.97544 -126.4511,0 -228.96368,-102.52274 -228.96368,-228.97544 0,-126.4527 102.51258,-228.97544 228.96368,-228.97544 z"
        />
        <path
          id="inner-circle"
          className="st0"
          d="m 349.99557,146.14303 c 112.57934,0 203.85439,91.27619 203.85439,203.85697 0,112.58965 -91.27505,203.85697 -203.85439,203.85697 -112.57935,0 -203.84553,-91.26732 -203.84553,-203.85697 0,-112.58965 91.25731,-203.85697 203.84553,-203.85697 z"
        />
        <circle
          id="bottom-circle"
          className="st2"
          cx="349.99554"
          cy="648.95593"
          r="20.603626"
          shapeRendering="geometricPrecision"
        />
        <circle
          id="bottom-right-circle"
          className="st2"
          cx="615.74759"
          cy="500.77547"
          r="20.603626"
          shapeRendering="geometricPrecision"
        />
        <circle
          id="top-right-circle"
          className="st2"
          cx="615.74759"
          cy="194.77547"
          r="20.603626"
          shapeRendering="geometricPrecision"
        />
      </svg>
      <div className="amount">
        <div className="chip-text">{value}</div>
      </div>
    </Container>
  );
};
